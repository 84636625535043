import React, {useEffect} from "react";
import {FieldArray, Field} from "formik";
import {TextField} from "formik-material-ui";
import {Button, Grid, IconButton} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {MULTIPLE_CHOICE, SINGLE_CHOICE} from "../../Utils/constants";

const TodoActionItemOption = (props) => {
    const {formRef, values, actionItem, actionItemIndex, todo} = props;

    const handleAddClick = (push) => {
        let actions = [...values?.actions];

        let options = [...actions[actionItemIndex]?.options];
        let lastIndex = options.length;

        const newOption = {
            key: actionItem === SINGLE_CHOICE ? 'radio_option' : `checkbox_option_${String.fromCharCode(65 + lastIndex).toLowerCase()}`,
            value: "",
        }

        push(newOption);
    }

    const handleDeleteClick = (remove, deleteIndex) => {
        remove(deleteIndex);

        let actions = [...values?.actions];

        let options = [...actions[actionItemIndex].options];

        options.forEach((option, index) => {
            if (deleteIndex < index) {
                option.key = actionItem === SINGLE_CHOICE ? 'radio_option' : `checkbox_option_${String.fromCharCode(64 + index).toLowerCase()}`;
            }
        })
    }

    return (
        <>
            <div>
                <FieldArray name={`actions[${actionItemIndex}].options`}>
                    {({push, remove, form}) => (
                        <>
                            {
                                form.values?.actions[actionItemIndex]?.options?.map((_item, index) => (
                                    <>
                                        <Grid key={`${actionItem}_${index}`} item xs={12} style={{display: "flex"}}>
                                            <Field
                                                id={`options_${index}`}
                                                name={`actions[${actionItemIndex}].options[${index}].value`}
                                                type='text'
                                                label={`Option ${String.fromCharCode(65 + index)}`}
                                                variant='outlined'
                                                margin='normal'
                                                size='small'
                                                component={TextField}
                                                fullWidth
                                            />
                                            <>

                                                {
                                                    index > 0 && <IconButton
                                                        id={`delete_${index}`}
                                                        variant='contained'
                                                        color='error'
                                                        sx={{margin: '15px 0px', padding: '8px'}}
                                                        onClick={() => handleDeleteClick(remove, index)}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                }
                                            </>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                        </Grid>
                                    </>
                                ))}
                            <Button
                                id={`add_${actionItem?.toLowerCase()}`}
                                variant='contained'
                                color='primary'
                                sx={{margin: '15px 0px', padding: '8px'}}
                                onClick={() => handleAddClick(push)}
                            >
                                <Add/> Add More
                            </Button>
                        </>
                    )}
                </FieldArray>
            </div>
        </>
    );
}

export default TodoActionItemOption;