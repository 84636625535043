import {Autocomplete, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MULTIPLE_CHOICE, SINGLE_CHOICE} from "../../Utils/constants";
import TodoActionItemOption from "./TodoActionItemOption";
import {ErrorMessage} from "formik";
import {AddBox, Close} from "@mui/icons-material";

const ManageTodoActionItem = (props) => {
    const {
        formRef,
        todoActionItemOptions,
        values,
        setFieldValue,
        errors,
        submitCount,
        todo,
        setShowAddTodoActionItem,
        setDialogMaxWidth,
        setTodoFormColumnSize
    } = props;

    const [singleChoice, setSingleChoice] = useState(false);
    const [multipleChoice, setMultipleChoice] = useState(false);
    const [singleChoiceIndex, setSingleChoiceIndex] = useState('');
    const [multipleChoiceIndex, setMultipleChoiceIndex] = useState('');
    const [todoActionItems, setTodoActionItems] = useState([]);

    useEffect(() => {
        let actions = [];

        todoActionItems.forEach((item) => {
            if (item.title === SINGLE_CHOICE) {
                actions.push({
                    "action_id": item.id,
                    "options": [{key: "radio_option", value: ""}]
                })
            } else if (item.title === MULTIPLE_CHOICE) {
                actions.push({
                    "action_id": item.id,
                    "options": [{key: "checkbox_option_a", value: ""}]
                })
            } else {
                actions.push({"action_id": item.id})
            }
        });

        setFieldValue('actions', actions);
        setSingleOrMultipleOptionSelected(todoActionItems);
    }, [todoActionItems]);

    useEffect(() => {
        if (todo?.actions) {
            const selected = todoActionItemOptions.filter((todoActionOption) => todo.actions.map(action => action.action_id).includes(todoActionOption.id))

            setTodoActionItems(selected);
        }
    }, [todo?.actions]);

    const setSingleOrMultipleOptionSelected = (selectedItems) => {
        let singleChoiceFound = false;
        let multipleChoiceFound = false;

        selectedItems?.forEach((todoActionItem, index) => {
            if (todoActionItem.title === SINGLE_CHOICE) {
                setSingleChoiceIndex(index);
                singleChoiceFound = true;
            } else if (todoActionItem.title === MULTIPLE_CHOICE) {
                setMultipleChoiceIndex(index);
                multipleChoiceFound = true;
            }
            return todoActionItem.title
        });

        if (multipleChoiceFound) {
            setMultipleChoice(true);
        } else {
            setMultipleChoice(false);
        }

        if (singleChoiceFound) {
            setSingleChoice(true);
        } else {
            setSingleChoice(false);
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{margin: "auto", paddingTop: 35}}>
                    <Typography
                        variant="span"
                        component="span">
                        Actions*
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{margin: "auto", paddingTop: 35}}>
                    <Autocomplete
                        id='todo-action-items'
                        name='actions'
                        disableClearable
                        margin='normal'
                        size='small'
                        options={todoActionItemOptions}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            const todoExists = todoActionItems.find((todo) => todo.id === newValue.id);

                            if (!todoExists) {
                                setTodoActionItems((prev) => [...prev, newValue]);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Todo Action Item*'
                                error={submitCount > 0 && errors.action}
                            />
                        )}
                    />
                    <Grid
                        container>
                        {
                            todoActionItems?.map((todoActionItem) => (
                                <Grid item
                                      sx={{
                                          margin: '4px 2px',
                                          padding: '2px',
                                          fontSize: '11px',
                                          border: "1px solid gray",
                                          borderRadius: '4px'
                                      }}>{todoActionItem.title}
                                    <IconButton sx={{padding: '4px', fontSize: '13px'}} onClick={() => {
                                        //remove todo action from list
                                        let _actions = values.actions.filter((action) => action.action_id !== todoActionItem.id);
                                        setFieldValue('actions', _actions);

                                        setTodoActionItems(todoActionItems.filter((item) => item.id !== todoActionItem.id));

                                    }}><Close
                                        sx={{fontSize: '15px'}}/></IconButton>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <div
                        style={{
                            color: "red",
                            fontSize: "0.75rem",
                            margin: "4px 14px 0px",
                        }}
                    >
                        <ErrorMessage name={"action"}/>
                    </div>
                </Grid>
                <Grid item xs={1} style={{paddingLeft: "0px"}}>
                    <Tooltip title='Add new action todo item' arrow placement='top'>
                        <IconButton
                            variant='contained'
                            color='primary'
                            style={{marginTop: "25px"}}
                            onClick={() => {
                                setShowAddTodoActionItem(true);
                                setDialogMaxWidth('lg')
                                setTodoFormColumnSize('7');
                            }}><AddBox color='white'/></IconButton>
                    </Tooltip>

                </Grid>
            </Grid>
            {
                singleChoice &&
                <Grid container spacing={2}>
                    <Grid item xs={3} style={{marginLeft: "auto", marginRight: "auto", marginTop: "45px"}}>
                        <Typography
                            variant="span"
                            component="span"
                        >
                            {SINGLE_CHOICE}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} style={{margin: "auto", paddingTop: 35}}>
                        <TodoActionItemOption formRef={formRef} values={values} actionItem={SINGLE_CHOICE}
                                              actionItemIndex={singleChoiceIndex}
                                              setFieldValue={setFieldValue}
                                              todo={todo}
                                              errors={errors}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            }
            {
                multipleChoice &&
                <Grid container spacing={2}>
                    <Grid item xs={3} style={{marginLeft: "auto", marginRight: "auto", marginTop: "45px"}}>
                        <Typography
                            variant="span"
                            component="span">
                            {MULTIPLE_CHOICE}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} style={{margin: "auto", paddingTop: 35}}>
                        <TodoActionItemOption formRef={formRef} values={values} actionItem={MULTIPLE_CHOICE}
                                              actionItemIndex={multipleChoiceIndex}
                                              setFieldValue={setFieldValue}
                                              todo={todo}
                                              errors={errors}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            }
        </>

    );
}

export default ManageTodoActionItem;