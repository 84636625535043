let domainUrl = "http://f5.erp2.dk";
let erpDomainUrl = "http://f5.erp.dk";
let economicAppSecretToken = "BDFiQ5sSWarGAJ7yKBDX08LSIcX4cMiGVrBhTRzKrUI";
let economicAgreementToken = "wW77Oq4Z1BNYIRKMSJONnjeN4mM26r5mCrTuKzfEAfc1";

if (window.location.href.indexOf("crm.f5") > -1) {
    // production
    domainUrl = "https://erp2.f5.dk";
    erpDomainUrl = "https://erp.f5.dk";
    economicAppSecretToken = "hBqD72bH14vlQaKboFKmtQEIVM8oSPE4ao6ITQj4KuQ";
    economicAgreementToken = "pD2oncfg9FLcjcZGXshycj9sMf2EbThMzl3I7rE3hFo1";
} else if (window.location.href.indexOf("crm2.f5") > -1) {
    // staging
    domainUrl = "https://stag2.f5.dk";
    erpDomainUrl = "https://stag1.f5.dk";
} else if (window.location.href.indexOf("crmstaging.f5") > -1) {
    // staging test
    domainUrl = "https://erp2staging.f5.dk";
}

export const BASE_DOMAIN_URL = domainUrl;
export const BASE_URL = `${domainUrl}/api/v1`;
export const ERP_BASE_URL = erpDomainUrl;
export const ECONOMIC_APP_SECRET = `${economicAppSecretToken}`;
export const ECONOMIC_AGREEMENT_TOKEN = `${economicAgreementToken}`;

// Initialize global timer variable for 10 hours auto logout
export const user_logout = {
    timer: null,
    time: 10, // It accepts hours
};

export const setRequestHeaders = () => {
    const defaultHeaders = {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
    };

    if (localStorage.getItem("token") !== null) {
        defaultHeaders["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    }

    return defaultHeaders;
};

export const fetchGet = async (url, retries = 0) => {
    for (let attempt = 0; attempt <= retries; attempt++) {
        try {
            const response = await fetch(BASE_URL + url, {
                method: "GET",
                headers: setRequestHeaders(),
            });

            return await handleResponse(response);
        } catch (error) {
            if (attempt < retries) continue;

            if (error?.code === 401) {
                const promise = new Promise((resolve, reject) => {
                    localStorage.removeItem("token");

                    if (!localStorage.getItem("token")) {
                        resolve("Redirecting you to the login screen. Please wait...");
                    } else {
                        reject(Error("Something went wrong while deleting user token"));
                    }
                });
                promise.then(
                    (_message) => {
                        window.location.href = "/";
                    },
                    (_error) => {
                    }
                );
            } else {
                throw new Error(error);
            }
        }
    }
};

export const fetchDelete = async (url) => {
    return fetch(BASE_URL + url, {
        method: "DELETE",
        headers: setRequestHeaders(),
    })
        .then(handleResponse)
        .then((response) => {
            return response;
        });
};

export const fetchPost = async (url, bodyObject, formData = false) => {
    if (formData) {
        return fetch(BASE_URL + url, {
            method: "POST",
            headers: setRequestHeaders(),
            body: bodyObject,
        })
            .then(handleResponse)
            .then((response) => {
                return response;
            });
    }

    return fetch(BASE_URL + url, {
        method: "POST",
        headers: setRequestHeaders(),
        body: JSON.stringify(bodyObject),
    })
        .then(handleResponse)
        .then((response) => {
            return response;
        });
};

export const fetchPatch = async (url, bodyObject) => {
    return fetch(BASE_URL + url, {
        method: "PATCH",
        headers: setRequestHeaders(),
        body: JSON.stringify(bodyObject),
    })
        .then(handleResponse)
        .then((response) => {
            return response;
        });
};

export const fetchPut = async (url, bodyObject) => {
    return fetch(BASE_URL + url, {
        method: "PUT",
        headers: setRequestHeaders(),
        body: JSON.stringify(bodyObject),
    })
        .then(handleResponse)
        .then((response) => {
            return response;
        });
};

const handleResponse = (response) => {
    if (!response.ok) {
        return response
            .text()
            .then((result) => JSON.parse(result))
            .then((result) =>
                Promise.reject({
                    code: result.error_code,
                    message: result.error,
                })
            );
    }

    return response
        .json()
        .then((result) => Promise.resolve(result))
        .catch(() =>
            Promise.resolve({
                status: response.status,
                message: response.statusText,
            })
        );
};

export const postFormData = async (url, bodyObject) => {
    return fetch(BASE_URL + url, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: bodyObject,
    })
        .then(handleResponse)
        .then((response) => {
            return response;
        });
};
