import {useCallback, useState} from "react";
import {Button, IconButton, Tooltip} from "@mui/material";
import {Add, Delete, Edit, PlaylistAdd} from "@mui/icons-material";
import * as React from "react";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import GridElement from "../../Common/GidElement";
import {makeAccessor, makeHeader, prepareFilterQuery, prepareSortQuery} from "../../Utils/gridUtils";
import {deleteTodo, getTodos} from "../../../API/clientRetention";
import ManageTodoModal from "./ManageTodoModal";
import AssignTodoToKpiModal from "./AssignTodoToKpiModal";
import {useNavigate} from "react-router-dom";

const TodoGrid = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);
    const [filters, setFilters] = useState([]);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [refreshGridData, setRefreshGridData] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [todo, setTodo] = useState(null);
    const [openManageTodoModal, setOpenManageTodoModal] = useState(false);
    const [openAssignTodoToKpiModal, setOpenAssignTodoToKpiModal] = useState(false);

    const [cols, _setCols] = useState([
        {
            column: "todos.id",
            accessor: makeAccessor("todos.id"),
            minWidth: 150,
            Header: makeHeader("todos.id"),
            filter: "fuzzyText",
        },
        {
            column: "todos.title",
            accessor: makeAccessor("todos.title"),
            minWidth: 150,
            Header: makeHeader("todos.title"),
            filter: "fuzzyText",
        },
        {
            column: "todos.description",
            accessor: makeAccessor("todos.description"),
            minWidth: 150,
            Header: makeHeader("todos.description"),
            filter: "fuzzyText",
        },
        {
            column: "todos.created_at",
            accessor: makeAccessor("todos.created_at"),
            minWidth: 150,
            Header: makeHeader("todos.created_at"),
            filter: "fuzzyText",
        },
        {
            Header: "Action",
            filter: "fuzzyText",
            Cell: ({row}) => (
                <div>
                    <Tooltip title='Edit' arrow placement='top'>
                        <IconButton
                            aria-label='edit todo'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={() => {
                                setOpenManageTodoModal(true);
                                setTodo(row.original);
                            }}
                            style={{
                                color: "#000",
                                fontSize: "17px",
                                fontWeight: "600",
                            }}
                        >
                            <Edit color='primary'/>
                        </IconButton>
                    </Tooltip>


                    <Tooltip title='Delete Todo' arrow placement='top'>
                        <IconButton
                            aria-label='delete todo'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={() => handleDeleteTodoClick(row.original)}
                            style={{
                                color: "#FF0000",
                                fontSize: "17px",
                                fontWeight: "600",
                            }}
                            disabled={isDeleting}
                        ><Delete/></IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ]);

    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {
        let _columns = columns.map((item) => item.column);
        _columns = _columns.filter((item) => item !== undefined);

        let query = prepareFilterQuery(filters, columns);
        let sort = prepareSortQuery(sortBy, columns);

        setLoading(true);

        getTodos(pageSize, _columns, pageIndex, query, sort).then((res) => {
            setData(res.data.main);
            setPageCount(res.data.offset.highest_offset);
            setRowsCount(res.data.offset.rows_count);
            setLoading(false);
        }).catch((error) => {
            toast.error(error.error);
            setLoading(false);
        })

        setRefreshGridData(false); //if grid fetches latest data then set refreshGridData to false
    }, []);

    const resetFilters = React.useCallback(() => setFilters([]), [setFilters]);

    const handleDeleteTodoClick = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsDeleting(true);
                deleteTodo(data.id).then((res) => {
                    toast.success(res.message);
                    setRefreshGridData(true);
                    setIsDeleting(false);
                }).catch((error) => {
                    toast.error(error);
                    setIsDeleting(false);
                });
            }
        });
    }

    const handleManageTodoClose = (refreshGrid = false) => {
        setOpenManageTodoModal(false);
        setRefreshGridData(refreshGrid);
        setTodo(null);
    };

    const handleAssignTodoToKpiClose = () => {
        setOpenAssignTodoToKpiModal(false);
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className='page-title'>Todos</h2>
                <div>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{marginRight: '5px'}}
                        onClick={() => setOpenManageTodoModal(true)}>
                        <Add aria-hidden='false' color='white'/>
                        Create Todo
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{marginLeft: '5px'}}
                        onClick={() => setOpenAssignTodoToKpiModal(true)}>
                        <PlaylistAdd aria-hidden='false' color='white'/>
                        Assign Todo
                    </Button>
                </div>
            </div>

            <GridElement
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={() => {
                }}
                initialSortBy='created_at'
                sortOrder='desc'
                hideSelection={true}
                refreshGridData={refreshGridData}
            />
            <ManageTodoModal
                open={openManageTodoModal}
                handleClose={handleManageTodoClose}
                data={todo}/>
            <AssignTodoToKpiModal
                open={openAssignTodoToKpiModal}
                handleClose={handleAssignTodoToKpiClose}
            />
        </>
    );
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <TodoGrid {...props} navigate={navigate}/>;
}

export default (WithNavigate);