import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useFormik} from "formik";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import * as yup from "yup";
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    TextField,
    Box,
    CircularProgress,
    Grid,
    FormControlLabel,
    Switch,
    Typography,
} from "@mui/material";
import {StaticDatePicker} from "@mui/x-date-pickers/StaticDatePicker";
import {selectors as accountSelectors} from "../../../../../Ducks/account";
import {selectors as opportunitySelectors, actions as opportunityActions} from "../../../../../Ducks/opportunity";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import {withStyles} from "@mui/styles";
import {getAllCalendars} from "../../../../../API/opportunity";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Placeholder from "ckeditor5-placeholder";
import Image from "@ckeditor/ckeditor5-image/src/image";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";

const types = ["phone_call", "phone_meeting"];

const editorConfiguration = {
    plugins: [
        List,
        Placeholder,
        Essentials,
        Bold,
        Italic,
        Paragraph,
        SimpleUploadAdapter,
        ImageUpload,
        Image,
        Heading,
        Link,
        AutoLink,
        Indent,
        IndentBlock,
        Font,
        Alignment,
        Underline,
        Strikethrough,
        Table,
        TableToolbar,
        HorizontalLine,
        BlockQuote,
    ],
    toolbar: [
        "heading",
        "|",
        "Placeholder",
        "|",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "outdent",
        "indent",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "horizontalLine",
        "blockQuote",
        "uploadImage",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "alignment:left",
        "alignment:right",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
    ],
    alignment: {
        options: ["left", "right"],
    },
    placeholderProps: {
        types: [
            "FROM_FULLNAME",
            "FROM_FIRSTNAME",
            "FROM_LASTNAME",
            "EMAIL_SUBJECT",
            "FROM_EMAIL",
            "TO_FULLNAME",
            "TO_FIRSTNAME",
            "FIRST_MEETING_DATE",
            "FIRST_MEETING_START_TIME",
            "FIRST_MEETING_END_TIME",
            "FIRST_MEETING_DATE_AND_TIME",
            "PRODUCT_NAME",
            "PRODUCT_LOCATION",
            "PRODUCT_ADDRESS",
            "PRODUCT_ZIP_CODE",
            "PRODUCT_CITY",
            "NETWORK_DIRECTOR_1_FIRSTNAME",
            "NETWORK_DIRECTOR_1_FULLNAME",
            "NETWORK_DIRECTOR_2_FIRSTNAME",
            "NETWORK_DIRECTOR_2_FULLNAME",
        ],
    },
    placeholderBrackets: {
        open: "[",
        close: "]",
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: "https://erp2.f5.dk/api/events/uploadimage",
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    },
};

const useStyles = (_theme) => ({
    select: {
        "& ul": {
            display: "grid",
        },
    },
});

function AddEventModal(props) {
    const [date, setDate] = React.useState(props.startDate ? props.startDate : new Date());
    const [startTime, setStartTime] = React.useState(props.startDate ? props.startDate : new Date());
    const [type, setType] = React.useState(types[0]);
    const [template, setTemplate] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [userCalendars, setUserCalendars] = React.useState([]);
    const [editor, setEditor] = React.useState(null);
    const {
        mails,
        getMails,
        isLoading,
        user,
        emailTemplate,
        opportunity,
        addCalendarEvent,
        classes,
        tab,
        cronofyAuthorized,
        calendar,
        currentEvent,
        plannedAction,
        latestPlannedAction,
        getLatestPlannedAction,
        jobEmail,
        handleTodoActionCallback
    } = props;

    const [isUpcomingEvent, setIsUpcomingEvent] = React.useState(false);

    const validationSchema = cronofyAuthorized
        ? yup.object({
            create_google_event: yup.boolean(),
            subject: yup.string().when("create_event", {
                is: true,
                then: () => yup.string().required("Subject is required when creating an event."),
            }),
            summary: yup.string().when("create_event", {
                is: true,
                then: () => yup.string(),
            }),
            calendar: yup.string().when("create_event", {
                is: true,
                then: () => yup.string().required("Calendar is required when creating an event."),
            }),
            endDuration: yup.string().required("End time is required"),
        })
        : yup.object({});

    const handleSubmit = (values) => {
        if (values.summary.length >= 4096) return alert("Summary too big.");

        values = {
            ...values,
            subject: values.subject.trim(),
            summary: values.summary.trim(),
        };

        const startDateTime = moment(date).set({
            minutes: moment(startTime).minute(),
            hours: moment(startTime).hour(),
        });
        const endDateTime = moment(startDateTime).set({
            minutes: startDateTime.minute() + parseInt(values.endDuration),
        });

        const obj = {
            action_type: type === "phone_call" ? "follow_up" : type,
            due_time: startDateTime.format("YYYY-MM-DDTHH:mm:ssZ"),
            end_time: endDateTime.format("YYYY-MM-DDTHH:mm:ssZ"),
            due_time_precise: 1,
            current_event:
                latestPlannedAction && latestPlannedAction.event_synced === 1 && latestPlannedAction.person_log_code === "missed_call" && currentEvent
                    ? currentEvent
                    : null,
        };

        let d = new Date();
        let YearMonth = d.getFullYear(d) + "-" + ("0" + (d.getMonth() + 1)).slice(-2);

        addCalendarEvent({
            id: opportunity.main.opportunity_id,
            obj: {...obj, ...values},
            date: YearMonth,
        })

        handleClose(true);
    };

    const formik = useFormik({
        validationSchema,
        onSubmit: handleSubmit,
        initialValues: {
            subject:
                latestPlannedAction && latestPlannedAction.event_synced === 1 && latestPlannedAction.person_log_code === "missed_call" && currentEvent
                    ? currentEvent.summary
                    : "",
            summary:
                latestPlannedAction && latestPlannedAction.event_synced === 1 && latestPlannedAction.person_log_code === "missed_call" && currentEvent
                    ? currentEvent.description
                    : "",
            calendar:
                latestPlannedAction && latestPlannedAction.event_synced === 1 && latestPlannedAction.person_log_code === "missed_call" && currentEvent
                    ? currentEvent.calendar_id
                    : "",
            endDuration: "30",
            create_google_event: true,
        },
    });

    const handleClose = (formSubmitted = false) => {
        props.handleClose(formSubmitted);
    };

    React.useEffect(() => {
        const isUpcomingEvent =
            typeof calendar != undefined && Array.isArray(calendar?.data?.main) && calendar?.data?.main.length > 0
                ? calendar?.data.main.filter((x) => x.opportunity_id == plannedAction?.opportunity_id).length > 0
                : calendar?.data?.main === undefined
                    ? false
                    : calendar?.data?.main?.opportunity_id == plannedAction?.opportunity_id
                        ? true
                        : false;
        setIsUpcomingEvent(isUpcomingEvent);

        if (plannedAction && handleTodoActionCallback) {
            handleTodoActionCallback(plannedAction.opportunity_action_due_time);
        }
    }, [calendar, plannedAction]);

    React.useEffect(() => {
        setType(tab);
    }, [tab]);

    React.useEffect(() => {
        if (opportunity) {
            const {opportunity_id} = opportunity?.main;

            if (opportunity_id) {
                getLatestPlannedAction(opportunity_id);
            }

            if (cronofyAuthorized) {
                var payload = {
                    productId: opportunity.main.product_id,
                    type: "calender",
                };
                getMails(payload);
            }
        }
    }, [opportunity, cronofyAuthorized]);

    React.useEffect(() => {
        if (latestPlannedAction) {
            if (latestPlannedAction.event_synced === 1) {
                if (latestPlannedAction.person_log_code == "missed_call" && currentEvent) {
                    formik.setFieldValue("subject", currentEvent.summary);
                    formik.setFieldValue("summary", currentEvent.description);
                    formik.setFieldValue("calendar", currentEvent.calendar_id);

                    const startDateTime = moment(currentEvent.start);
                    const endDateTime = moment(currentEvent.end);

                    setDate(startDateTime.format("YYYY-MM-DD"));
                    setStartTime(startDateTime.format("YYYY-MM-DDTHH:mm"));

                    const differenceInMins = endDateTime.diff(startDateTime, "minutes");

                    if (differenceInMins != formik.values.endDuration) {
                        if (["15", "30", "45", "60"].includes(differenceInMins.toString())) formik.setFieldValue("endDuration", differenceInMins);
                    }
                }

                formik.setFieldValue("create_google_event", true);
            } else {
                formik.setFieldValue("create_google_event", false);
                formik.setFieldValue("subject", "");
                formik.setFieldValue("summary", "");
                formik.setFieldValue("calendar", "");
            }
        }
    }, [currentEvent, latestPlannedAction]);

    React.useEffect(() => {
        if (latestPlannedAction && formik.values.create_google_event) {
            if (latestPlannedAction.event_synced === 1) {
                if (latestPlannedAction.person_log_code == "missed_call" && currentEvent) {
                    formik.setFieldValue("subject", currentEvent.summary);
                    formik.setFieldValue("summary", currentEvent.description);
                    formik.setFieldValue("calendar", currentEvent.calendar_id);

                    const startDateTime = moment(currentEvent.start);
                    const endDateTime = moment(currentEvent.end);

                    setDate(startDateTime.format("YYYY-MM-DD"));
                    setStartTime(startDateTime.format("YYYY-MM-DDTHH:mm"));

                    const differenceInMins = endDateTime.diff(startDateTime, "minutes");

                    if (differenceInMins != formik.values.endDuration) {
                        if (["15", "30", "45", "60"].includes(differenceInMins.toString())) formik.setFieldValue("endDuration", differenceInMins);
                    }
                }
            } else {
                formik.setFieldValue("subject", "");
                formik.setFieldValue("summary", "");
                formik.setFieldValue("calendar", "");
            }
        }
    }, [formik.values.create_google_event]);

    React.useEffect(() => {
        if (emailTemplate !== null && template !== null) {
            formik.setFieldValue("summary", emailTemplate.main.body);
        }
    }, [emailTemplate]);

    React.useEffect(() => {
        if (cronofyAuthorized) loadCalendars();
        else setLoading(false);
    }, [cronofyAuthorized]);

    React.useEffect(() => {
        return () => {
            formik.resetForm();
            editor?.setData("");
        };
    }, [editor]);

    const handleRedirect = () => {
        props.handleClose(true);
        props.handleTabChange("person");
    };

    const loadCalendars = async () => {
        const {data} = await getAllCalendars();
        setUserCalendars(data?.calendars);
        setLoading(false);
    };

    const makeHeader = (key) => {
        key = key.replaceAll("_", " ");
        let splitStr = key.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

            key = splitStr.join(" ");
        }

        return key;
    };

    const getTemplate = (e) => {
        const templateId = e.target.value;
        const template = mails.main.templates.find((t) => t.template_id == templateId);
        formik.setFieldValue("subject", template.template_subject || template.template_name);
        formik.setFieldValue("summary", template.template_body);
        setTemplate(templateId);
    };

    const getPersonName = () => {
        if (opportunity) {
            const persons = opportunity.related_objects.persons;
            if (persons) {
                const personId = Object.keys(persons)[0];
                return persons[personId].person_firstname + " " + persons[personId].person_lastname;
            }
        }
        return "";
    };

    return (
        <div>
            <Dialog maxWidth={cronofyAuthorized ? "lg" : "md"} fullWidth={!(loading || isLoading)} open={props.open}
                    onClose={() => handleClose()}>
                <DialogContent>
                    {loading || isLoading ? (
                        <CircularProgress/>
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            {isUpcomingEvent &&
                                "You already have a planned action for this opportunity so you cannot plan a new one until this has been carried out"}
                            {!isUpcomingEvent && (
                                <>
                                    Plan a{" "}
                                    <FormControl style={{width: "180px"}}>
                                        <InputLabel id='demo-simple-select-label'>Type</InputLabel>
                                        <Select
                                            labelId='demo-simple-select-label'
                                            name='type'
                                            id='demo-simple-select'
                                            value={type}
                                            label='Age'
                                            onChange={(e) => setType(e.target.value)}
                                            size={"small"}
                                            MenuProps={{classes: {paper: classes.select}}}
                                        >
                                            {types.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {makeHeader(item)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>{" "}
                                    on <b>{moment(date).format("MMMM D, YYYY, HH:mm")}</b> with <b>{getPersonName()}</b>
                                    <Box
                                        sx={{
                                            "& .MuiTextField-root": {m: 1, width: "100%"},
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {cronofyAuthorized && (
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id='create_google_event'
                                                                name='create_google_event'
                                                                checked={formik.values.create_google_event}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                helperText={formik.touched.create_google_event && formik.errors.create_google_event}
                                                            />
                                                        }
                                                        label='Create Event on Google Calendar'
                                                        error={formik.touched.create_google_event && Boolean(formik.errors.calendar)}
                                                    />
                                                    {(jobEmail == null || jobEmail[0]?.job_email == null || jobEmail[0]?.job_email == "") &&
                                                    formik.values.create_google_event ? (
                                                        <>
                                                            <Typography style={{color: "red"}}>
                                                                *Person's email is missing. Please update email address
                                                                of the person!
                                                            </Typography>
                                                            {props.handleTabChange && (
                                                                <Typography
                                                                    onClick={() => handleRedirect()}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        textDecoration: "underline",
                                                                        color: "blue"
                                                                    }}
                                                                >
                                                                    Click here to update.
                                                                </Typography>
                                                            )}
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {formik.values.create_google_event && (
                                                        <>
                                                            <TextField
                                                                id='calendar'
                                                                name='calendar'
                                                                select
                                                                label='Select Calendar'
                                                                value={formik.values.calendar}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.calendar && Boolean(formik.errors.calendar)}
                                                                helperText={formik.touched.calendar && formik.errors.calendar}
                                                            >
                                                                {userCalendars.map((option) => (
                                                                    <MenuItem key={option.calendar_id}
                                                                              value={option.calendar_id}>
                                                                        {option.calendar_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>

                                                            <TextField
                                                                id='template'
                                                                name='template'
                                                                select
                                                                label='Select Template'
                                                                value={template}
                                                                onChange={getTemplate}
                                                            >
                                                                {mails &&
                                                                    mails.main.templates
                                                                        .filter(
                                                                            (option) =>
                                                                                option.created_by !== null &&
                                                                                option.created_by === user?.user_details?.user_id
                                                                        )
                                                                        .map((option) => (
                                                                            <MenuItem key={option.template_id}
                                                                                      value={option.template_id}>
                                                                                {option.template_name}
                                                                            </MenuItem>
                                                                        ))}
                                                            </TextField>

                                                            <TextField
                                                                label='Subject'
                                                                variant='outlined'
                                                                id='subject'
                                                                name='subject'
                                                                value={formik.values.subject}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.subject && Boolean(formik.errors.subject)}
                                                                helperText={formik.touched.subject && formik.errors.subject}
                                                            />

                                                            <CKEditor
                                                                id='summary'
                                                                name='summary'
                                                                editor={ClassicEditor}
                                                                config={editorConfiguration}
                                                                data={formik.values.summary}
                                                                onReady={setEditor}
                                                                onChange={(_event, editor) => {
                                                                    formik.setFieldValue("summary", editor.getData());
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </Grid>
                                            )}
                                            <Grid item xs={cronofyAuthorized ? 6 : 12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <StaticDatePicker
                                                        displayStaticWrapperAs='desktop'
                                                        value={date}
                                                        onChange={setDate}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                    <Grid container spacing={4}>
                                                        <Grid item>
                                                            <TimePicker
                                                                value={startTime}
                                                                onChange={setStartTime}
                                                                ampm={false}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Grid>

                                                        <Grid item>
                                                            <FormControl>
                                                                <FormLabel
                                                                    id='demo-row-radio-buttons-group-label'>Duration</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                                                    name='endDuration'
                                                                    value={formik.values.endDuration}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                >
                                                                    <FormControlLabel value='15' control={<Radio/>}
                                                                                      label='15 minute'/>
                                                                    <FormControlLabel value='30' control={<Radio/>}
                                                                                      label='30 minute'/>
                                                                    <FormControlLabel value='45' control={<Radio/>}
                                                                                      label='45 minute'/>
                                                                    <FormControlLabel value='60' control={<Radio/>}
                                                                                      label='60 minute'/>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            )}
                            <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>
                                {!isUpcomingEvent && (
                                    <Button
                                        disabled={
                                            (jobEmail == null || jobEmail[0]?.job_email == null || jobEmail[0]?.job_email == "") &&
                                            formik.values.create_google_event
                                        }
                                        variant='contained'
                                        color='secondary'
                                        type='submit'
                                        style={{marginRight: "12px"}}
                                    >
                                        {latestPlannedAction &&
                                        latestPlannedAction.event_synced == 1 &&
                                        latestPlannedAction.person_log_code == "missed_call"
                                            ? "Reschedule"
                                            : "Confirm"}
                                    </Button>
                                )}
                                <Button variant='contained' color='error' onClick={() => handleClose()}>
                                    {"Cancel"}
                                </Button>
                            </div>
                        </form>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.applyEmailTemplate_loading(state),
    calendar: opportunitySelectors.calendar(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    mails: opportunitySelectors.mails(state),
    emailTemplate: opportunitySelectors.emailTemplate(state),
    cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
    plannedAction: opportunitySelectors.plannedActionResponse(state),
    user: accountSelectors.user(state),
    latestPlannedAction: opportunitySelectors.latestPlannedActionResponse(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addCalendarEvent: opportunityActions.addCalendarEvent,
            getMails: opportunityActions.getMails,
            applyEmailTemplate: opportunityActions.applyEmailTemplate,
            getLatestPlannedAction: opportunityActions.latestPlannedAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(AddEventModal));
