import React, {useState, useEffect} from "react";
import {selectors as accountSelectors} from "../../../Ducks/account";
import {actions as usersActions, selectors as usersSelectors} from "../../../Ducks/users";
import {bindActionCreators} from "redux";
import {actions as opportunityActions} from "../../../Ducks/opportunity";
import {actions as productsActions} from "../../../Ducks/products";
import {actions as eventsOverviewActions, selectors as eventsOverviewSelectors} from "../../../Ducks/eventsOverview";
import {actions as companiesActions} from "../../../Ducks/companies";
import Typography from "@mui/material/Typography";
import {Autocomplete, TextField as MaterialTextField} from "@mui/material";
import TeamsDropDowns from "../CaseOverview/Widgets/TeamsDropDowns";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import {MONTHS} from "../../Utils/constants";
import {connect} from "react-redux";
import {cloneDeep} from "lodash";
import {withStyles} from "@mui/styles";

const useStyles = _theme => ({
    container: {
        textAlign: 'left',
    },
    tabs: {
        marginTop: '0'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    headline: {
        margin: '0 0 10px',
        minWidth: '300px',
        fontWeight: '400',
        fontSize: '20px',
        position: 'relative',
        flex: 1
    },
    stepper: {
        flex: 2
    },
    status: {
        color: '#fff',
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: '400',
        padding: '3px 10px',
        borderRadius: '4px',
        marginBottom: '4px',
        position: 'absolute',
        top: '11px'
    },
    opportunityID: {
        margin: '0 5px'
    },
    tabsStyling: {
        padding: '12px 0 !important',
        backgroundColor: '#f5f5f5 !important',
        color: '#3a3641 !important',
        border: '1px solid #dcdbdb !important',
        opacity: '1 !important',
        fontWeight: '600 !important',
        textTransform: 'capitalize'
    },
    selectBox: {
        marginLeft: '10px',
        padding: '10px'
    },
    attending: {
        background: '#68a54d',
        color: '#fff'
    },
    not_attending: {
        background: '#db6669',
        color: '#fff'
    },
    unknown: {
        background: '',
        color: '#000'
    },
    tentative: {
        background: '#f9fd01',
        color: '#000'
    },
    resale_attending: {
        background: '#5ecd5e',
        color: '#fff'
    },
    resale_not_attending: {
        background: '#e44848',
        color: '#fff'
    },
    resale_unknown: {
        background: '#e2e23b',
        color: '#000'
    },
    resale_tentative: {
        background: '#f1b851',
        color: '#000'
    },
    hide: {
        display: 'none'
    },
    form: {
        display: 'flex'
    },
    member: {
        position: 'relative'
    },
    indicator: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '15px',
        height: '15px',
        background: 'blue'
    }
});

function TodoOverview(props) {
    const {canViewTeamStates, team, resetTeamDetails, teamDetails, getTeamDetails, classes} = props;
    const [teams, setTeams] = useState([]);
    const [currentMonth, setCurrentMonth] = useState('');
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        initialize();
        fetchTeams();
    }, []);

    useEffect(() => {
        if (team) {
            let _teams = team['rows']?.map((item) => {
                item.key = item.key
                item.label = item.team_name;
                return item;
            });
            setTeams(_teams);
        }
    }, [team]);

    const initialize = () => {
        const {isNetworkDirector} = props;
        const start = new Date(new Date().setHours(0, 0, 0, 0));
        const end = isNetworkDirector ? new Date(new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0));
        const range = [start, end]
        const m = moment(start).format("MM");
        const current = MONTHS.filter((item) => item.value === parseInt(m));
        setCurrentMonth(current[0], range);
    }

    const fetchTeams = () => {
        const {getTeams} = props;
        getTeams();
    }

    const filterTodosWithTeams = (filter) => {
        let _filters = cloneDeep(filters);
        let filterFound = false;

        _filters.forEach((item, index) => {
            if (filter) {
                if (filter.hasOwnProperty("user_id")) {
                    if (item.id === "user_id") {
                        item.value = filter.user_id;
                        filterFound = true;
                    } else if (item.id === "team_id") {
                        _filters.splice(index, 1, {
                            id: "user_id",
                            value: filter.user_id,
                        });
                        filterFound = true;
                    }
                } else if (filter.hasOwnProperty("team_id")) {
                    if (item.id === "team_id") {
                        item.value = filter.team_id;
                        filterFound = true;
                    } else if (item.id === "user_id") {
                        _filters.splice(index, 1, {
                            id: "team_id",
                            value: filter.team_id,
                        });
                        filterFound = true;
                    }
                }
            } else {
                if (item.id === "user_id" || item.id === "team_id") {
                    _filters.splice(index, 1);
                    filterFound = true;
                }
            }
        });

        if (!filterFound && filter) {
            if (filter.hasOwnProperty("team_id")) {
                _filters.push({id: "team_id", value: filter.team_id});
            } else if (filter.hasOwnProperty("user_id")) {
                _filters.push({id: "user_id", value: filter.user_id});
            }
        }

        setFilters(_filters);
    };

    return (
        <>
            <div className={classes.title}>
                <h2 className={classes.headline}>
                    Todo Overview
                </h2>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={8} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                            {
                                canViewTeamStates ?
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={teams}
                                        sx={{width: 200, marginRight: '10px'}}
                                        onChange={(_event, value) => {
                                            if (value === null) {
                                                resetTeamDetails();
                                                // getCasesOverview();

                                            } else {
                                                resetTeamDetails();
                                                getTeamDetails({"team_id": value?.team_id});
                                                let query = `equals[team_id]=${value?.team_id}`;
                                                // getCasesOverview(query);
                                            }
                                        }}
                                        renderOption={(props, option, _state) => {
                                            return (
                                                <div {...props}>
                                                    <div style={{
                                                        textAlign: 'left',
                                                        padding: '0',
                                                        fontSize: '13px',
                                                        borderBottom: '1px solid #eaeaea'
                                                    }}>
                                                        <span>{`${option.label}`}</span>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        renderInput={(params) => <MaterialTextField {...params} label="Teams"/>}
                                    /> : <></>
                            }
                            {teamDetails?.map((item, index) => (
                                <TeamsDropDowns
                                    teamDetails={item}
                                    key={index}
                                    dropDownKey={index}
                                    getTeamDetails={getTeamDetails}
                                    resetTeamDetails={resetTeamDetails}
                                    filterWithTeamsCallback={filterTodosWithTeams}
                                />
                            ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => ({
    networkDirectors: eventsOverviewSelectors.networkDirectors(state),
    canViewTeamStates: accountSelectors.canViewTeamStates(state),
    team: usersSelectors.caseTeams(state),
    teamDetails: usersSelectors.teamDetails(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getTeams: usersActions.getCaseTeams,
        getTeamDetails: usersActions.getTeamDetails,
        resetTeamDetails: usersActions.resetTeamDetails,
    },
    dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(TodoOverview));