import React from 'react';
import {Card, FormControlLabel, Checkbox, CardContent, Typography, CardActions, Button} from '@mui/material';
import {withStyles} from '@mui/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const useStyles = _theme => ({
    card: {
        // display: 'flex',
        backgroundColor: '#fff'
        // height: 200
    },
    cardMedia: {
        flex: 1,
        backgroundColor: '#fff',
        objectFit: 'none'
    },
    cardContent: {
        flex: 4
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginRight: '-8px',
        marginBottom: '-8px',
    }
});

function ReadyToClose(props) {
    const {classes, overview, eventsToOpportunities} = props;
    const selectedEvents = eventsToOpportunities || {};
    const productTypes = overview.related_objects.product_types;
    const cvr = overview.main.opportunity_cvr;
    const bkEmail = overview.main.opportunity_bookkeeper_email;
    const ean = overview.main.opportunity_ean;
    const count = Object.keys(selectedEvents).length;
    const totalRequiredEvents = (productTypes && productTypes[Object.keys(productTypes)[0]].product_type_events_required) || 1;
    const companyName = overview && overview.related_objects.persons[overview.related_objects.jobs[overview.main.job_id].person_id].company_name;
    const address1 = overview && overview.main.opportunity_address_1;
    const city = overview && overview.main.opportunity_address_city;
    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Typography variant="h6" component="div">
                    Ready to close the sale
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={true}
                            checked={count >= totalRequiredEvents}
                            // onChange={(event) => setDense(event.target.checked)}
                        />
                    }
                    label={<p>Events selected <b>{count}</b> out of <b>{totalRequiredEvents}</b></p>}
                />
                <br/>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={true}
                            checked={!!companyName && !!address1 && !!city}
                            // onChange={(event) => setDense(event.target.checked)}
                        />
                    }
                    label={'Company info'}
                />
                <br/>

                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={true}
                            checked={!!cvr}
                            // onChange={(event) => setDense(event.target.checked)}
                        />
                    }
                    label={'CVR Number'}
                />
                <br/>

                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={true}
                            checked={!!bkEmail || !!ean}
                            // onChange={(event) => setDense(event.target.checked)}
                        />
                    }
                    label={'Bookkeeper Email / EAN'}
                />
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button variant="contained" color="secondary" endIcon={<ArrowRightAltIcon/>} size="small"
                        onClick={() => props.handleTabChange('deal')}>Deal</Button>
            </CardActions>
        </Card>
    );
}


export default withStyles(useStyles)(ReadyToClose);