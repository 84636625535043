import {fetchDelete, fetchGet, fetchPatch, fetchPost} from "./util";

export const getBookkeepers = (results, columns, pageIndex, query, sort) => {
    return fetchGet(`/bookkeepers?results=${results}&columns=${columns}&offset=${pageIndex + 1}${query}${sort}`).then(res => res);
}

export const createBookkeeper = (data) => {
    return fetchPost(`/bookkeepers`, data).then(res => res);
}

export const deleteBookkeeper = (bookkeeperId) => {
    return fetchDelete(`/bookkeepers/${bookkeeperId}`).then(res => res);
}

export const updateBookkeeper = (bookkeeperId, data) => {
    return fetchPatch(`/bookkeepers/${bookkeeperId}`, data).then(res => res);
}