import {
    Box, Card,
    Checkbox, CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {Check, Info} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {getPersonTodos, getPersonTodosHistory, markTodoDone} from "../../../../API/clientRetention";
import {COMPLETED, PLANNED_ACTION} from "../../../Utils/constants";
import {formatDate} from "../../../Utils/dateUtils";
import InfiniteScrollList from "../../../Common/InfiniteScrollList";
import {toast} from "react-toastify";
import AddEventModal from "../../Calendar/Widgets/CalendarWidget/AddEventModal";
import {selectors as opportunitySelectors} from "../../../../Ducks/opportunity";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useNavigate} from "react-router-dom";


function TodoActionHistoryNoteTextInput(props) {
    const {participantTodoId, action, handleHistoryNoteTextInputCallback} = props;
    const [text, setText] = useState('');
    const [responseText, setResponseText] = useState('');

    useEffect(() => {
        const {response} = action;
        const responseFound = response.find((item) => item.action_id === action.id);

        if (responseFound) {
            setResponseText(responseFound.response);
        }

    }, [action]);

    return (
        <Grid item sx={{padding: '15px', marginLeft: '30px'}}>
            {
                responseText ? <div><i sx={{fontSize: '10px'}}>History Note: </i>{responseText}</div> :
                    <>
                        <TextField
                            name='text'
                            size='small'
                            onInput={(event) => setText(event.target.value)}
                        />
                        <LoadingButton
                            variant='outlined'
                            onClick={() => handleHistoryNoteTextInputCallback(participantTodoId, action, text)}
                            sx={{marginLeft: '10px'}}>Save</LoadingButton>
                    </>
            }
        </Grid>
    );
}

function TodoActionSingleRadioGroup(props) {
    const {participantTodoId, action, handleSingleRadioClickCallback} = props;
    const [responseYesOrNo, setResponseYesOrNo] = useState('');

    return (
        <Grid item sx={{padding: '10px', marginLeft: '30px', display: 'flex'}}>
            <RadioGroup
                row
                size="small"
                aria-labelledby='todo-overview-single-radio-buttons-group-label'
                name='yes-no'
                onChange={(event) => {
                    setResponseYesOrNo(event.target.value);
                }}>
                <FormControlLabel
                    value='Yes'
                    control={<Radio/>}
                    label='Yes'/>
                <FormControlLabel
                    value='No'
                    control={<Radio/>}
                    label='No'/>
            </RadioGroup>
            <LoadingButton
                variant='outlined'
                onClick={() => handleSingleRadioClickCallback(participantTodoId, action, responseYesOrNo)}
                sx={{marginLeft: '10px'}}>Save</LoadingButton>
        </Grid>
    )
}

function TodoActionMultiRadioGroup(props) {
    const {action, participantTodoId, handleMultiRadioClickCallback} = props;
    const [selected, setSelected] = useState('');
    const [responseOption, setResponseOption] = useState([]);

    useEffect(() => {
        const {response} = action;
        const responseFound = response.find((item) => item.action_id === action.id);

        if (responseFound) {
            setResponseOption(responseFound.response);
        }

    }, [action]);

    return (
        <Grid item sx={{padding: '15px', marginLeft: '30px', display: 'flex'}}>
            <RadioGroup
                row
                size="small"
                aria-labelledby='todo-overview-multi-radio-buttons-group-label'
                name={action.options[0].key}
                onChange={(e) => {
                    const {value} = e.target;
                    setSelected(value);
                }}>
                {
                    action.options.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            name={option.key}
                            value={option.value}
                            control={<Radio
                                checked={(responseOption.length > 0 && responseOption === option.value) || selected === option.value}/>}
                            label={option.value}
                            disabled={action?.completed}/>
                    ))
                }
            </RadioGroup>
            {
                !action?.completed &&
                <LoadingButton
                    variant='outlined'
                    onClick={() => handleMultiRadioClickCallback(participantTodoId, action, selected)}
                    sx={{marginLeft: '10px'}}>Save</LoadingButton>
            }
        </Grid>
    )
}

function TodoActionTextArea(props) {
    const {participantTodoId, actionId, handleTextAreaCallback} = props;
    const [text, setText] = useState('');

    return (
        <Grid item sx={{padding: '15px'}}>
            <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                onChange={(event) => setText(event.target.value)}
            />
            <LoadingButton
                variant='outlined'
                onClick={() => {
                    handleTextAreaCallback(participantTodoId, actionId, text)
                }}
                sx={{marginTop: '10px'}}>Save</LoadingButton>
        </Grid>
    )
}

function TodoActionCheckbox(props) {
    const {participantTodoId, action, handleCheckboxChangeCallback} = props;
    const [selected, setSelected] = useState([]);
    const [responseOptions, setResponseOptions] = useState([]);

    useEffect(() => {
        const {response} = action;
        const responseFound = response.find((item) => item.action_id === action.id);

        if (responseFound) {
            setResponseOptions(responseFound.response);
        }

    }, [action]);

    return (
        <Grid item xs={12} sx={{padding: '15px', marginLeft: '30px'}}>
            <Grid container alignItems="center">
                <FormGroup row>
                    {
                        action?.options.map((option) => (
                            <Grid item xs={4} sx={{margin: '0px'}}>
                                <FormControlLabel
                                    control={<Checkbox color="primary"
                                                       checked={responseOptions?.some((item) => item.key === option.key)} // ✅ Check if value exists
                                    />}
                                    label={option.value}
                                    name={option.key}
                                    labelPlacement="end"  // Positions label to the right of the checkbox
                                    disabled={action.completed}
                                    onChange={(event) => {
                                        const {name, checked} = event.target;
                                        let prevSelected = [...selected];
                                        let nameFound = false;
                                        let selectedOption = action.options.find((option) => option.key === name);

                                        prevSelected.forEach((option, index) => {
                                            if (option.key === name) {
                                                option.checked = checked;
                                                nameFound = true;
                                                if (!checked) {
                                                    prevSelected.splice(index, 1);
                                                }
                                            }
                                        });

                                        if (!nameFound) {
                                            setSelected([...prevSelected, selectedOption]);
                                        } else {
                                            setSelected([prevSelected]);
                                        }
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </FormGroup>
            </Grid>
            {
                !action.completed && <LoadingButton
                    variant='outlined'
                    onClick={() => handleCheckboxChangeCallback(participantTodoId, action, selected)}>Save</LoadingButton>
            }
        </Grid>
    );
}

const TodoOverviewTab = (props) => {
    const {person, events, opportunity} = props;
    const [checkedTodos, setCheckedTodos] = useState([]);
    const [refreshTodos, setRefreshTodos] = useState(false);
    const [openAddEventModal, setOpenAddEventModal] = useState(false);
    const [eventToUpdate, setEventToUpdate] = useState(null);
    const [jobEmail, setJobEmail] = useState(null);
    const [todoPlannedAction, setTodoPlannedAction] = useState(null);
    const observerRef = useRef(null);
    const navigate = useNavigate();

    const fetchPersonTodos = (page) => {
        return getPersonTodos(person.person_id, page).then((res) => res);
    }

    const fetchTodoHistory = (page) => {
        return getPersonTodosHistory(person.person_id, page).then((res) => res);
    }

    const handleTodoCheckboxCallback = (e, todo) => {
        const {checked} = e.target;
        let checkedTodo = checkedTodos.find((item) => (item.id === todo.id));

        if (checkedTodo) {
            setCheckedTodos((prevTodos) => prevTodos.map((item) =>
                item.id === todo.id ? {...item, checked: checked} : item
            ))
        } else {
            setCheckedTodos((prevTodos) => [...prevTodos, {id: todo.id, checked: checked}]);
        }

        const plannedAction = todo?.actions.filter((item) => item.type === PLANNED_ACTION);

        if (checked && plannedAction?.length > 0) {
            if (opportunity) {
                setOpenAddEventModal(true);
                setTodoPlannedAction(todo);
            } else {
                toast.error('Open opportunity does not exist for this person');
            }
        }
    }

    const handleHistoryNoteTextInputCallback = (participantTodoId, action, text) => {
        if (text?.length) {
            let payload = {
                'type': action.type,
                'action_id': action.id,
                'response': text,
            }

            markDone(participantTodoId, payload);
        }
    }

    const handleSingleRadioClickCallback = (participantTodoId, action, responseYesOrNo) => {
        if (responseYesOrNo?.length) {
            let payload = {
                'type': action.type,
                'action_id': action.id,
                'response': responseYesOrNo,
            }

            markDone(participantTodoId, payload);
        }
    }

    const handleMultiRadioClickCallback = (participantTodoId, action, selected) => {
        if (selected?.length) {
            let payload = {
                'type': action.type,
                'action_id': action.id,
                'response': selected
            }

            markDone(participantTodoId, payload);
        }
    }

    const handleTextAreaCallback = (participantTodoId, actionId, text) => {
        if (text?.length) {
            markDone(participantTodoId, actionId);
        }
    }

    const handleCheckboxChangeCallback = (participantTodoId, action, selected) => {
        if (selected?.length) {
            let payload = {
                'type': action.type,
                'action_id': action.id,
                'response': selected
            }

            markDone(participantTodoId, payload);
        }
    }

    const handlePlannedActionCallback = (plannedActionDate) => {
        if (todoPlannedAction) {
            const plannedAction = todoPlannedAction?.actions.find((item) => item.type === PLANNED_ACTION);

            let payload = {
                'type': plannedAction.type,
                'action_id': plannedAction.id,
                'response': plannedActionDate
            }

            markDone(todoPlannedAction.id, payload);
        }
    }

    const markDone = (participantTodoId, payload) => {
        markTodoDone(participantTodoId, payload).then((res) => {
            if (res.data) {
                setRefreshTodos(true);
            }
        }).finally(() => {
            setTodoPlannedAction(null);
        });
    }

    const isCompleted = (todo) => {
        return (todo.status === COMPLETED)
    }

    const renderActionComponent = (action, participantTodoId) => {
        switch (action.type) {
            case 'history_note':
                return <TodoActionHistoryNoteTextInput participantTodoId={participantTodoId} action={action}
                                                       handleHistoryNoteTextInputCallback={handleHistoryNoteTextInputCallback}/>;
            case 'single_radio':
                return <TodoActionSingleRadioGroup participantTodoId={participantTodoId} action={action}
                                                   handleSingleRadioClickCallback={handleSingleRadioClickCallback}/>;
            case 'multi_radio':
                return <TodoActionMultiRadioGroup participantTodoId={participantTodoId} action={action}
                                                  handleMultiRadioClickCallback={handleMultiRadioClickCallback}/>;
            case 'text_only':
                return <TodoActionTextArea participantTodoId={participantTodoId} actionId={action.action_id}
                                           handleTextAreaCallback={handleTextAreaCallback}/>
            case 'checkbox':
                return <TodoActionCheckbox participantTodoId={participantTodoId} action={action}
                                           handleCheckboxChangeCallback={handleCheckboxChangeCallback}/>
        }
    }

    const renderTodoListItemComponent = (todo, type) => {
        return (
            <ListItem
                key={todo.id}
                sx={{
                    width: '100%',
                    margin: '8px 0',
                    padding: 0,
                    borderRadius: '4px',
                    backgroundColor: isCompleted(todo) ? '#f5f5f5' : 'white',
                }}>
                <Grid container>
                    <Grid item xs={9}>
                        <Box display='flex' justifyContent='left'>
                            <Box
                                sx={{
                                    width: '5px',
                                    height: '80px',
                                    backgroundColor: `${todo.kpi_criteria?.color_code}`,
                                    borderRadius: '4px 0 0 4px', // Rounds left edges of the bar
                                }}
                            />
                            <Checkbox
                                edge="end"
                                checked={isCompleted(todo) || checkedTodos.find((checkedTodo) => checkedTodo.id === todo.id)?.checked}
                                disabled={type === 'history' || isCompleted(todo)}
                                onClick={(e) => handleTodoCheckboxCallback(e, todo)}
                            />
                            <ListItemText
                                primary={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}>
                                        {todo.title}

                                        {
                                            todo.description &&
                                            <Tooltip title={todo.description} arrow
                                                     position='top'>
                                                <IconButton size="small" sx={{marginLeft: 1}}>
                                                    <Info fontSize="small" color="action"/>
                                                </IconButton>
                                            </Tooltip>
                                        }

                                    </Box>
                                }
                                secondary={`Associated KPIs: ${todo.kpi_title} - ${todo.kpi_criteria?.title}`}
                                sx={{
                                    marginLeft: 2, // Add spacing after color bar
                                    marginTop: '15px',
                                    textDecoration: isCompleted(todo) ? 'line-through' : 'none',
                                    color: isCompleted(todo) ? 'gray' : 'inherit',
                                }}
                            >
                            </ListItemText>
                        </Box>
                        {
                            checkedTodos.find((item) => item.id === todo.id)?.checked && !isCompleted(todo) && todo.actions?.map((action) => (
                                    <Grid container justifyContent='left' padding='10'>
                                        {renderActionComponent(action, todo.id)}
                                    </Grid>
                                )
                            )
                        }
                    </Grid>
                    <Grid item xs={3} sx={{margin: '25px 0px'}}>
                        {formatDate(todo.due_date)}
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    const renderActiveTodoListItem = (todo) => {
        return renderTodoListItemComponent(todo, 'active');
    }

    const renderHistoryTodoListItem = (todo) => {
        return renderTodoListItemComponent(todo, 'history');
    }

    const handleAddEventModalClose = (formSubmitted = false) => {
        setOpenAddEventModal(false);
    };

    useEffect(() => {
        if (opportunity) {
            const _eventToUpdate = events?.filter(e => e.event_id?.split('__').length === 2 && e.event_id?.split('__')[1] == opportunity.opportunity_id)
                .sort((a, b) => new Date(b.created) - new Date(a.created)).at(0);

            setEventToUpdate(_eventToUpdate);
            const _jobEmail = null;//Object.values(opportunity.related_objects.jobs).filter(item => item.job_is_primary == 1);
            setJobEmail(_jobEmail);
        }
    }, [opportunity, events]);

    return (
        <>
            <Grid container>
                <Grid item xs={9}>
                    <Typography
                        variant='span'
                        component='span'
                        sx={{fontWeight: 'bold'}}>
                        Todo Items
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        variant='span'
                        component='span'
                        sx={{fontWeight: 'bold'}}>
                        Due Date
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <InfiniteScrollList renderListItemComponent={renderActiveTodoListItem}
                                        fetchData={fetchPersonTodos} refresh={refreshTodos}/>
                </Grid>
            </Grid>
            <Grid container sx={{marginTop: "20px"}}>
                <Grid item xs={9}>
                    <Typography
                        variant='span'
                        component='span'
                        sx={{fontWeight: 'bold'}}>
                        History Todo Items
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        variant='span'
                        component='span'
                        sx={{fontWeight: 'bold'}}>
                        Due Date
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sx={{height: '300px', overflow: "auto"}}>
                    <InfiniteScrollList renderListItemComponent={renderHistoryTodoListItem}
                                        fetchData={fetchTodoHistory}/>
                </Grid>
            </Grid>
            <AddEventModal open={openAddEventModal} handleClose={handleAddEventModalClose} opportunity={opportunity}
                           currentEvent={eventToUpdate} jobEmail={jobEmail} startDate={new Date()}
                           endDate={new Date()}
                           tab="phone_call"
                           handleTabChange={() => navigate(`/opportunities/id/${opportunity.opportunity_id}`)}
                           handleTodoActionCallback={handlePlannedActionCallback}
            />
        </>

    )
}

const mapStateToProps = (state) => ({
    events: opportunitySelectors.events(state),
    opportunity: opportunitySelectors.opportunity(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TodoOverviewTab);