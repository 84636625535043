import {
    Autocomplete,
    Card,
    FormControlLabel,
    Grid,
    TextField as MaterialTextField,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import EventGrid from "../ChangePersonGroup/EventGrid";
import {checkFutureEventFromPurchaseStart} from "../../Utils/dateUtils";
import {DoubleArrow} from "@mui/icons-material";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import moment from "moment/moment";
import {getStatusClass} from "../../Utils/gridUtils";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {addDays} from "date-fns";
import {ERP_BASE_URL} from "../../../API/util";

const TransferMembershipOverview = (props) => {
    const {
        products,
        person,
        newPerson,
        product,
        processing,
        handleProcessClick,
        setProductCallback,
        newEvents,
        eventOverview,
        classes,
        setPurchaseStopCallback
    } = props;
    const [newProduct, setNewProduct] = useState(null);
    const [meetingsCount, setMeetingsCount] = useState(0);
    const [purchaseStart, setPurchaseStart] = useState(new Date());
    const [purchaseStop, setPurchaseStop] = useState(null);
    const [newProductUpcomingEvents, setNewProductUpcomingEvents] = useState([]);
    const [doProceed, setDoProceed] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (product && products) {
            const selectedProduct = products.find((productItem) => productItem.product_id === product.product_id);

            setNewProduct(selectedProduct);
        }
    }, [product, products]);

    useEffect(() => {
        if (newEvents && newProduct) {
            setNewProductUpcomingEvents(newEvents);
            setMeetingsCount(newEvents.length)

            adjustPurchaseStop(newProduct.events, newEvents);
        }
    }, [newEvents, newProduct]);

    const fetchNewProductUpcomingEvents = (events, meetingsLeft, purchaseStartDate) => {
        let upcomingEvents = [];

        events.some((event) => {
            if (checkFutureEventFromPurchaseStart(event, purchaseStartDate)
                && upcomingEvents.length < meetingsLeft
            ) {
                upcomingEvents.push(event);
            }

            return false;
        });

        return upcomingEvents
    }

    const adjustPurchaseStop = (productEvents, selectedEvents) => {
        if (productEvents?.length > selectedEvents?.length) {

            let lastEvent = selectedEvents[selectedEvents.length - 1];
            let nextEvent = productEvents.find((event) => event.event_start > lastEvent.event_start);
            let purchaseStopDate = new Date(nextEvent.event_start);
            purchaseStopDate = addDays(purchaseStopDate, -1);

            setPurchaseStop(purchaseStopDate);
            setPurchaseStopCallback(purchaseStopDate);

            if (errors.hasOwnProperty('purchase_stop')) {
                let _errors = {...errors};

                delete _errors.purchase_stop;

                setErrors(_errors);
            }
        } else {
            setPurchaseStop(null);
            setPurchaseStopCallback(null);

            let _errors = {...errors};
            _errors.purchase_stop = 'Required';

            setErrors(_errors);
        }
    }

    const disableProcessButton = () => {
        return !newPerson || !newProduct || !newEvents || !newEvents?.length || !doProceed || Object.keys(errors).length;
    }

    return (
        <>
            <Grid container spacing={2} sx={{padding: "10px"}}>
                <Grid item xs={5}>
                    <Card className='rounded-box'>
                        <Typography variant="span" component="span" sx={{paddingBottom: "10px", fontWeight: 'bold'}}>
                            Existing Member
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Person Name:</TableCell>
                                        <TableCell>
                                            <a target="_blank"
                                               href={`${ERP_BASE_URL}/#/contacts/persons/${person.person_id}`}>
                                                {person?.person_firstname} {person?.person_lastname}
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Mobile Number:</TableCell>
                                        <TableCell>{person?.person_mobile_number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Company Name:</TableCell>
                                        <TableCell>{person?.company_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Title:</TableCell>
                                        <TableCell>{person?.job_title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Email:</TableCell>
                                        <TableCell>{person?.job_email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Phone:</TableCell>
                                        <TableCell>{person?.job_phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LinkedIn Profile:</TableCell>
                                        <TableCell><a href={person?.person_linkedin_url}
                                                      target="_blank">{person?.person_linkedin_member_id}</a></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Region:</TableCell>
                                        <TableCell>{person?.person_region}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Country:</TableCell>
                                        <TableCell>{person?.person_country}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes?.flex} style={{marginTop: '20px'}}>
                            {
                                eventOverview?.map((item, index) => {
                                    const color = getStatusClass(item);
                                    return (
                                        <Tooltip
                                            key={index}
                                            title={
                                                <React.Fragment>
                                                    <div>{item.event_name}</div>
                                                    <div>Date: {moment(item.event_start).format("DD/MM/YYYY HH:mm")}</div>
                                                    <div>
                                                        {item.event_has_happened ? (
                                                            <>
                                                                {item.rating_nps ? <div>NPS
                                                                    Rating: {item.rating_nps}</div> : <></>}{" "}
                                                                {item.rating_overall ? <div>Overall
                                                                    Rating: {item.rating_overall}</div> : <></>}{" "}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <div className={classes[color]}></div>
                                        </Tooltip>
                                    );
                                })
                            }
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={2} sx={{margin: 'auto', textAlign: 'center'}}>
                    <DoubleArrow sx={{fontSize: '50px'}}/>
                </Grid>
                <Grid item xs={5}>
                    <Card className='rounded-box'>
                        <Typography variant="span" component="span" sx={{paddingBottom: "10px", fontWeight: 'bold'}}>
                            New Member
                        </Typography>

                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Person Name:</TableCell>
                                        <TableCell>
                                            <a target="_blank"
                                               href={`${ERP_BASE_URL}/#/contacts/persons/${newPerson.person_id}`}>
                                                {newPerson?.person_firstname} {newPerson?.person_lastname}
                                            </a></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Mobile Number:</TableCell>
                                        <TableCell>{newPerson?.person_mobile_number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Company Name:</TableCell>
                                        <TableCell>{newPerson?.company_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Title:</TableCell>
                                        <TableCell>{newPerson?.job_title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Email:</TableCell>
                                        <TableCell>{newPerson?.job_email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Job Phone:</TableCell>
                                        <TableCell>{newPerson?.job_phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LinkedIn Profile:</TableCell>
                                        <TableCell><a href={newPerson?.person_linkedin_url}
                                                      target="_blank">{newPerson?.person_linkedin_member_id}</a></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Region:</TableCell>
                                        <TableCell>{newPerson?.person_region}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Person Country:</TableCell>
                                        <TableCell>{newPerson?.person_country}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{padding: "10px"}}>
                <Grid item xs={5}>
                    <Card className='rounded-box' sx={{backgroundColor: "#F8F8F8"}}>
                        <Box display='flex' justifyContent='center'>
                            <FormControlLabel
                                control={<Checkbox checked={doProceed}
                                                   onChange={(e) => setDoProceed(e.target.checked)}/>}
                                label='Are you sure want to continue?'
                                sx={{'& .MuiFormControlLabel-label': {fontSize: '0.875rem'}}}/>
                        </Box>
                        <Box display='flex' justifyContent='center'>
                            <LoadingButton type='submit'
                                           variant='contained'
                                           color='primary'
                                           onClick={handleProcessClick}
                                           loading={processing}
                                           style={{marginTop: "10px", marginRight: "5px"}}
                                           disabled={disableProcessButton()}
                            >
                                Process
                            </LoadingButton>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={2} sx={{margin: 'auto'}}>
                </Grid>
                <Grid item xs={5}>
                    <Card className='rounded-box'>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        name='newProduct'
                                        margin='normal'
                                        size='small'
                                        id='products'
                                        disablePortal
                                        disableClearable
                                        options={products}
                                        value={newProduct}
                                        getOptionLabel={(option) => option.product_name}
                                        onChange={(_event, newValue) => {
                                            setNewProduct(newValue);
                                            let _meetingCount = meetingsCount;

                                            if (newValue.events?.length <= meetingsCount) {
                                                _meetingCount = newValue.events?.length - 1;
                                                setMeetingsCount(_meetingCount);
                                            }

                                            const _newProductUpcomingEvents = fetchNewProductUpcomingEvents(newValue.events, _meetingCount, purchaseStart);

                                            setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                            setProductCallback(newValue, _newProductUpcomingEvents, purchaseStart);
                                            adjustPurchaseStop(newValue.events, _newProductUpcomingEvents);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Products'
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type='number'
                                        label='Meetings Count'
                                        variant='outlined'
                                        margin='normal'
                                        size='small'
                                        required
                                        fullWidth
                                        inputProps={{min: 1, max: 5}}
                                        value={meetingsCount}
                                        onInput={(event) => {
                                            const {value} = event.target;

                                            setMeetingsCount(value.replace(/[^0-9]/g, ""));// Remove any character that is not a digit

                                            if (newProduct?.events) {
                                                const _newProductUpcomingEvents = fetchNewProductUpcomingEvents(newProduct.events, value, purchaseStart);

                                                setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                                setProductCallback(newProduct, _newProductUpcomingEvents, purchaseStart);
                                                adjustPurchaseStop(newProduct.events, _newProductUpcomingEvents);
                                            }
                                        }}
                                        onKeyDown={(event) => {
                                            const {key, target} = event;
                                            const {value} = target;

                                            if (key === "Backspace" || key === "Delete") {
                                                event.preventDefault();
                                            } else {
                                                // Prevent entering values greater than 5
                                                if (parseFloat(value + key) > 5) {
                                                    event.preventDefault();
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            name='purchase_start'
                                            label='Purchase Start*'
                                            inputFormat='dd/MM/yyyy'
                                            value={purchaseStart} // Set the initial value
                                            onChange={(newValue) => {
                                                setPurchaseStart(newValue);

                                                const _newProductUpcomingEvents = fetchNewProductUpcomingEvents(newProduct.events, meetingsCount, newValue);

                                                setNewProductUpcomingEvents(_newProductUpcomingEvents);
                                                setProductCallback(newProduct, _newProductUpcomingEvents, newValue);
                                                adjustPurchaseStop(newProduct.events, _newProductUpcomingEvents);

                                                let _errors = {};

                                                if (!newValue) {
                                                    _errors.purchase_start = 'Required';
                                                }

                                                setErrors(_errors);
                                            }}
                                            renderInput={(startProps) => (
                                                <MaterialTextField
                                                    {...startProps}
                                                    sx={{
                                                        width: "100%",
                                                        marginTop: "10px",
                                                    }}
                                                    error={errors.purchase_start}
                                                />
                                            )}
                                        />
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "0.75rem",
                                                margin: "4px 14px 0px",
                                            }}>{errors.purchase_start}</div>
                                    </LocalizationProvider>

                                </Grid>

                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            name='purchase_stop'
                                            label='Purchase Stop*'
                                            inputFormat='dd/MM/yyyy'
                                            value={purchaseStop} // Set the initial value
                                            onChange={() => {
                                            }}
                                            disabled={true}
                                            renderInput={(startProps) => (
                                                <MaterialTextField
                                                    {...startProps}
                                                    sx={{
                                                        width: "100%",
                                                        marginTop: "10px",
                                                    }}
                                                    error={errors.purchase_stop}
                                                />
                                            )}
                                        />
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "0.75rem",
                                                margin: "4px 14px 0px",
                                            }}>{errors.purchase_stop}</div>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            newProductUpcomingEvents?.length > 0 &&
                            <Grid item xs={12}>
                                <Box style={{padding: "12px"}}>
                                    <Typography variant='h6' component='h6'>
                                        Upcoming events ({newProduct?.product_name})
                                    </Typography>
                                    <Card className='rounded-box'>
                                        <EventGrid events={newProductUpcomingEvents}/>
                                    </Card>
                                </Box>
                            </Grid>
                        }
                    </Card>

                </Grid>
            </Grid>
        </>
    )
};

export default TransferMembershipOverview;