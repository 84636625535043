import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {selectors as opportunitySelectors} from '../../../../Ducks/opportunity';
import {actions as companiesActions} from '../../../../Ducks/companies';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {bindActionCreators} from 'redux';
import Typography from '@mui/material/Typography';
import {Button, DialogActions, DialogContent, Grid, IconButton} from '@mui/material';
import {withStyles} from '@mui/styles';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {ArrowRightAlt} from '@mui/icons-material';

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    },
    span: {
        fontSize: '12px'
    }
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(4),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    },
    '.MuiTypography-h6': {
        paddingTop: '0px',
        paddingBottom: 0,
        textAlign: 'center'
    },
    'hr': {
        borderBottom: '1px solid #d7d7d7',
        width: '90%'
    },
    '.MuiGrid-container': {
        borderBottom: '1px solid #d7d7d7'
    },
    'h5': {
        fontSize: '14px',
        textTransform: 'capitalize'
    }
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const RowItem = ({title, value, formData, classes}) => {

    const formatTitle = () => {
        if (title === 'company_name') {
            let arr = title.split('_');
            return arr.join(" ");
        } else {
            let arr = title.split('_');
            arr.splice(0, 1);
            if (arr.length > 1) {
                return arr.join(" ");
            } else {
                return arr
            }
        }

    }

    return (
        <Grid container sx={{marginBottom: '15px'}} spacing={1}>
            <Grid sx={{textAlign: 'center'}} item xs={4}>
                <Typography className={classes.span} component="span">
                    {formData[title]}
                </Typography>
            </Grid>
            <Grid sx={{textAlign: 'center'}} item xs={4}>
                <Typography component="h5" variant="h5">
                    <b>{formatTitle()}</b>
                </Typography>
                <ArrowRightAlt/>
            </Grid>
            <Grid sx={{textAlign: 'center'}} item xs={4}>
                <Typography className={classes.span} component="span">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    )
}

const BorderElement = ({gap}) => {
    return (
        <div className='border-element'
             style={{margin: '0 auto', width: '90%', paddingBottom: gap, borderBottom: '1px solid #d7d7d7'}}/>
    )
}


const CvrModal = (props) => {
    const {onClose, open, classes, formData, cvrData} = props;
    const [info, setInfo] = React.useState({});

    useEffect(() => {
        setInfo(Object.assign({}, cvrData))
    }, [cvrData]);


    const handleClose = (_info = null) => {
        onClose("cvr");
    };

    const handleConfirm = () => {
        Object.keys(info).forEach((k, i) => {
            props.formReference.current.setFieldValue(k, info[k]);
        });
        if (props.updateCompanyData) {
            props.updateCompany({id: props.companyID, obj: info});
        }
        handleClose(info);
    }

    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BorderElement gap="50px"/>
            <BootstrapDialogTitle>The following information will be replaced if you confirm the
                changes</BootstrapDialogTitle>
            <BorderElement gap="10px"/>
            <DialogContent>
                {
                    Object.keys(info).map((key, index) => {
                        return <RowItem classes={classes} title={key} key={index} value={info[key]}
                                        formData={formData}/>
                    })
                }
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color='inherit'
                        onClick={handleConfirm}>Confirm</Button>
                <Button className={classes.button} variant="contained" color="error"
                        onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        updateCompany: companiesActions.updateCompany,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(CvrModal));