import React, {useEffect, useRef, useState} from "react";
import {CircularProgress, List} from "@mui/material";

const InfiniteScrollList = (props) => {
    const {renderListItemComponent, fetchData, refresh} = props;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const observerRef = useRef(null);

    const loadMore = () => {
        if (loading || page > lastPage) return;

        setLoading(true);

        fetchData(page).then((res) => {
            setLoading(false);
            setData((prev) => [...res.data.data]);
            setPage((prev) => prev + 1);
            setLastPage(res.data.last_page);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMore();
                }
            },
            {threshold: 1}
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => observer.disconnect();
    }, [data]);

    useEffect(() => {
        if (refresh) {
            setLoading(true);

            fetchData(0).then((res) => { //bring the first page
                setLoading(false);
                setData(res.data.data);
                setPage(1);
                setLastPage(res.data.last_page);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [refresh])

    return (
        <>
            <List sx={{width: '100%'}}>
                {
                    data?.map((item) => (
                        <>
                            {
                                renderListItemComponent(item)
                            }
                        </>
                    ))
                }
            </List>
            <div ref={observerRef} style={{textAlign: "center", margin: "20px 0"}}>
                {loading && <CircularProgress/>}
            </div>

        </>
    )
}

export default InfiniteScrollList