import {
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";
import * as React from "react";

const MultipleEanModal = (props) => {
    const {open, data, onClose} = props;
    const handleClose = () => {
        onClose();
    }

    const itemClickHandler = (index) => {
        const ean = data.find((_, i) => i === index).ean;
        onClose(ean);
    }

    return (
        <Dialog key={open ? 'open' : 'closed'} open={open} scroll="paper" fullWidth maxWidth={'md'}
                sx={{'& .MuiDialog-paper': {maxHeight: '80vh'}}}>
            <DialogTitle
                sx={{backgroundColor: '#1565c0', color: 'white'}}>Choose EAN</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500, 100],
                })}
            >
                <Close/>
            </IconButton>
            <DialogContent sx={{padding: '14px', backgroundColor: '#F7F7F7'}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Card sx={{
                            padding: '14px',
                            marginRight: '7px',
                            backgroundColor: 'white',
                        }}>
                            <Typography
                                variant="span"
                                component="span"
                                sx={{fontWeight: 'bold', marginBottom: '10px'}}
                            >
                                Choose EAN from list
                            </Typography>
                            <List>
                                {data?.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            backgroundColor: 'white',
                                            color: '#000',

                                            '&:hover': {
                                                backgroundColor: 'lightblue',
                                                color: '#000',
                                                borderRadius: "4px",
                                                textDecoration: 'underline'
                                            }
                                        }}
                                        onClick={() => itemClickHandler(index)}
                                    >
                                        <ListItemText primary={item.ean}/>
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default MultipleEanModal;