import React from "react";
import {List, ListItem} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {actions as opportunityActions, selectors as opportunitySelectors} from "../../Ducks/opportunity";
import {bindActionCreators} from "redux";
import {OpportunityDirtyConfirmationPopup} from "../Containers/Opportunity/OpportunityDetails";
import {generateIDForAUT} from "../Utils/generateID";


const theme = createTheme({});

const MyMenu = (props) => {
    const {item, user, isOpportunityDirty, setIsOpportunityDirty} = props;
    const capabilities = user.user_details.capabilities.map((item) => item.capability_title);
    
    const handleClick = (event) => {
        const {href} = event.target;

        if (isOpportunityDirty) {
            event.preventDefault();
            OpportunityDirtyConfirmationPopup().then((result) => {
                if (result.isConfirmed) {
                    window.location.href = href; // Manually navigate
                    setIsOpportunityDirty(false);
                }
            })
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={item.items ? 'menu-item menu-item-has-children' : 'menu-item'}
                style={{ marginLeft: '8px', marginRight: '8px' }}
                id={generateIDForAUT(`${item.title}-div`)}>
                {
                    item.items && (item.capabilities === undefined || item.capabilities.some(r => capabilities.includes(r))) ?
                        <>
                            <Link
                                style={{textDecoration: 'none', color: 'white'}}
                                to={{
                                    pathname: item.to
                                }
                                }
                                id={generateIDForAUT(`${item.title}-Link`)}
                                onClick={handleClick}
                            >
                                {item.title}
                            </Link>
                            <List className="sub-menu">
                                {
                                    item.items.filter((i) => i.capabilities === undefined || i.capabilities.some(r => capabilities.includes(r))).map((i, index) =>
                                        <ListItem key={index}>
                                            <Link
                                                style={{
                                                    textDecoration: 'none',
                                                    fontSize: '14px',
                                                    color: "#000",
                                                    padding: '5px 20px'
                                                }}
                                                to={{
                                                    pathname: i.to
                                                }
                                                }
                                                id={generateIDForAUT(`${i.title}-List-ListItem-Link`)}
                                                onClick={handleClick}
                                            >
                                                {i.title}
                                            </Link>
                                        </ListItem>)
                                }
                            </List>
                        </>
                        : (item.capabilities === undefined || item.capabilities.some(r => capabilities.includes(r))) && (
                        <Link
                            style={{textDecoration: 'none', color: 'white'}}
                            to={{
                                pathname: item.to
                            }
                            }
                            id={generateIDForAUT(`${item.title}-Link`)}
                            onClick={handleClick}
                        >
                            {item.title}
                        </Link>
                    )
                }
            </div>

        </ThemeProvider>
    );
};

const mapStateToProps = (state) => ({
    isOpportunityDirty: opportunitySelectors.isOpportunityDirty(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        setIsOpportunityDirty: opportunityActions.setIsOpportunityDirty,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyMenu);
