import * as React from 'react';
import {withStyles} from '@mui/styles';
import {actions as opportunityActions, selectors as opportunitySelectors} from '../../../Ducks/opportunity';
import {actions as dashboardActions, selectors as dashboardSelectors} from '../../../Ducks/dashboard';
import {selectors as accountSelectors} from '../../../Ducks/account';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, CircularProgress, Grid, Tab, Tabs} from '@mui/material';
import {tabListData} from './data';
import Overview from './Tabs/Overview';
import CompanyTab from './Tabs/Company';
import PersonTab from './Tabs/Person';
import Stepper from '../../Common/Stepper';
import Product from './Tabs/Product';
import Deal from './Tabs/Deal';
import Actions from './Tabs/Actions';
import History from './Tabs/History';
import ActiveCall from './Tabs/ActiveCall';
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import Swal from "sweetalert2";

toast.configure();

const useStyles = _theme => ({
    container: {
        textAlign: 'left',
    },
    tabs: {
        marginTop: '24px'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        position: 'relative',
        zIndex: 999
    },
    headline: {
        margin: '0',
        fontWeight: '100',
        fontSize: '26px',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    headlineSpan: {
        margin: '0',
        fontWeight: '100',
        fontSize: '26px',
        position: 'relative',
        flex: 1,
        display: 'flex',
        alignItems: 'center'
    },
    user: {
        fontSize: '15px',
        fontWeight: 500,
        marginBottom: '5px'
    },
    stepper: {
        display: 'flex',
        alignItems: "center",
        flex: 2,
        marginTop: '3px',
        marginBottom: '5px'
    },
    status: {
        color: '#fff',
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: '400',
        padding: '3px 10px',
        borderRadius: '4px',
    },
    opportunityID: {
        margin: '0 5px'
    },
    tabsStyling: {
        padding: '12px 0 !important',
        backgroundColor: '#f5f5f5 !important',
        color: '#3a3641 !important',
        border: '1px solid #dcdbdb !important',
        opacity: '1',
        fontWeight: '600 !important',
        flex: '1 !important',
        textTransform: 'capitalize !important'
    },
    switcher: {
        display: "flex !important",
        justifyContent: "flex-end !important",
        margin: "-3px 0 0 10px !important"
    },
    switcherBtn: {
        padding: '0 !important',
        marginRight: "5px !important",
        minWidth: "auto !important"
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 99999
    }
});

export const OpportunityDirtyConfirmationPopup = () => {
    return (
        Swal.fire({
            icon: "warning",
            title: "Unsaved Changes",
            text: "You will lose unsaved data",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then((result) => result)
    );
}

class OpportunityDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'overview',
            selectedTab2: 'deal',
            selectedOpportunity: props.params.id,
            pipelineStageId: null,
            assignedOpportunity: false,
            tabList: tabListData,
            currentStageID: null,
            previousOpportunity: null,
            nextOpportunity: null,
            selfOpportunity: false
        }
    }

    componentDidMount() {
        const {pipelines, getPipelines, user} = this.props;
        this.getOpportunityDetailsOverview();

        if (user && user.user_details.user_id && pipelines === null) {
            getPipelines(user.user_details.user_id);
        }

        // Add Hotkeys Support
        window.addEventListener('keydown', this.handleHotkeys)
    }

    componentWillUnmount() {
        // we can now remove this as we have handled this in componentDidUpdate by removing the params
        window.history.replaceState(null, null, window.location.origin + window.location.pathname);
        // Remove Hotkeys support
        window.removeEventListener('keydown', this.handleHotkeys)
    }

    handleHotkeys = (event) => {
        if (event.ctrlKey == true && event.shiftKey && event.keyCode == 88) {
            this.moveNext(); // x
        }
        if (event.ctrlKey == true && event.shiftKey && event.keyCode == 90) {
            this.moveBack(); // z
        }
    }

    moveNext = () => {
        const {navigate} = this.props;
        const {nextOpportunity} = this.state;
        if (nextOpportunity !== -1 && nextOpportunity !== undefined) {
            navigate(`/opportunities/id/${nextOpportunity}?opp=${nextOpportunity}`);
        }
    }

    moveBack = () => {
        const {navigate} = this.props;
        const {previousOpportunity} = this.state;
        if (previousOpportunity !== -1 && previousOpportunity !== undefined) {
            navigate(`/opportunities/id/${previousOpportunity}?opp=${previousOpportunity}`);
        }
    }

    getOpportunityDetailsOverview = () => {
        const {getOpportunityDetails} = this.props;
        const {selectedOpportunity} = this.state;
        getOpportunityDetails({id: selectedOpportunity, preloadNextPrevOpportunity: true});
    }

    handleChange = (_e, newValue) => {
        const {isOpportunityDirty, setIsOpportunityDirty} = this.props;

        if (newValue !== this.state.selectedTab2) {
            if (isOpportunityDirty) {
                OpportunityDirtyConfirmationPopup().then((result) => {
                    if (result.isConfirmed) {
                        this.setState({selectedTab: newValue});
                        setIsOpportunityDirty(false);
                    }
                });
            } else {
                this.setState({selectedTab: newValue});
            }
        } else {
            toast.error('Tab is already opened!', {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            });
        }
    }

    handleChange2 = (e, newValue) => {
        const {isOpportunityDirty, setIsOpportunityDirty} = this.props;

        if (newValue !== this.state.selectedTab) {
            if (isOpportunityDirty) {
                OpportunityDirtyConfirmationPopup().then((result) => {
                    if (result.isConfirmed) {
                        this.setState({selectedTab2: newValue});
                        setIsOpportunityDirty(false);
                    }
                });
            } else {
                this.setState({selectedTab2: newValue});
            }
        } else {
            toast.error('Tab is already opened!', {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 2000
            });
        }
    }

    renderSelectedComponent = (selectedTab) => {
        const {
            opportunity,
            getOpportunityDetails_loading,
            checkEvent_loading,
            checkEvent,
            unCheckEvent,
            eventsToOpportunities
        } = this.props;
        switch (selectedTab) {
            case 'overview':
                return <Overview
                    opportunity={opportunity}
                    isLoading={getOpportunityDetails_loading}
                    handleTabChange={(d) => this.handleChange({}, d)}
                    checkEvent_loading={checkEvent_loading}
                    checkEvent={checkEvent}
                    unCheckEvent={unCheckEvent}
                    assignedOpportunity={opportunity && opportunity.main.self_opportunity}
                    showActiveTab={this.showActiveTab}
                    eventsToOpportunities={eventsToOpportunities}
                />;
            case 'company':
                return <CompanyTab
                    opportunity={opportunity}
                    showActiveTab={this.showActiveTab}
                />;
            case 'person':
                return <PersonTab showActiveTab={this.showActiveTab} opportunity={opportunity}/>;
            case 'product':
                return <Product opportunity={opportunity}/>;
            case 'actions':
                return <Actions opportunity={opportunity} handleTabChange={(d) => this.handleChange({}, d)}/>;
            case 'deal':
            case 'deal *':
                return <Deal selectedOpportunity={this.state.selectedOpportunity}/>;
            case 'history':
                return <History opportunity={opportunity}
                                assignedOpportunity={opportunity && opportunity.main.self_opportunity}/>;
            case 'active call':
                return <ActiveCall handleTabChange={(d) => this.handleChange2({}, d)} opportunity={opportunity}/>;
            default:
                return <Overview opportunity={opportunity} eventsToOpportunities={eventsToOpportunities}
                                 assignedOpportunity={opportunity && opportunity.main.self_opportunity}/>;
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        const {
            opportunity,
            getStages,
            getOpportunityDetails,
            isOpportunityDirty
        } = this.props;
        const {currentStageID, tabList} = this.state;
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get("tab");
        const search = urlParams.get("search");
        const opp = urlParams.get("opp");

        const pipeLineStageId = opportunity?.main.pipeline_stage_id;
        if (opportunity !== prevProps.opportunity) {
            const pipeLineId = opportunity.related_objects.pipeline_stages[pipeLineStageId].pipeline_id;

            // Frontend handling
            const _sales_overview_opportunities = localStorage.getItem('sales_overview_opportunities') ? JSON.parse(localStorage.getItem('sales_overview_opportunities')) : undefined;
            const _stage_opportunities = _sales_overview_opportunities && _sales_overview_opportunities[pipeLineStageId] ? _sales_overview_opportunities[pipeLineStageId].opportunities : undefined;

            if (_stage_opportunities && currentStageID === null) {
                this.setState({
                    currentStageID: pipeLineStageId,
                    nextOpportunity: this.getItem(_stage_opportunities, parseInt(this.props.params.id), 'next'),
                    previousOpportunity: this.getItem(_stage_opportunities, parseInt(this.props.params.id), 'previous'),
                    selfOpportunity: opportunity.main.self_opportunity
                });
            }
            if (_stage_opportunities && prevProps.opportunity && opportunity.main.pipeline_stage_id === currentStageID && this.state.selectedOpportunity !== this.props.params.id) {
                this.setState({
                    nextOpportunity: this.getItem(_stage_opportunities, parseInt(this.props.params.id), 'next'),
                    previousOpportunity: this.getItem(_stage_opportunities, parseInt(this.props.params.id), 'previous'),
                    selfOpportunity: opportunity.main.self_opportunity,
                    selectedOpportunity: this.props.params.id
                });
            }
            // Frontend handling
            // opportunity switching
            getStages({id: pipeLineId});
            this.setState({pipelineStageId: pipeLineStageId})
        }

        if (tab && pipeLineStageId) {
            const name = tab;
            window.history.replaceState(null, null, window.location.origin + window.location.pathname);
            getOpportunityDetails({id: this.props.params.id, preloadNextPrevOpportunity: true});
            const pipeLineId = opportunity.related_objects.pipeline_stages[pipeLineStageId].pipeline_id;
            getStages({id: pipeLineId});
            this.setState({pipelineStageId: pipeLineStageId})
            this.setState({selectedTab2: name});
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

        if (search || opp) {
            window.history.replaceState(null, null, window.location.origin + window.location.pathname);
            getOpportunityDetails({id: this.props.params.id, preloadNextPrevOpportunity: true});
            const pipeLineStageId = opportunity?.main.pipeline_stage_id;
            const pipeLineId = opportunity?.related_objects.pipeline_stages[pipeLineStageId].pipeline_id;
            getStages({id: pipeLineId});
            this.setState({pipelineStageId: pipeLineStageId})
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

        if (prevProps.isOpportunityDirty !== isOpportunityDirty) {
            this.setState({
                tabList: tabList.map(x => {
                    if (x.name.indexOf('deal') > -1) {
                        if (isOpportunityDirty) {
                            x.name = 'deal *';
                        } else {
                            x.name = 'deal';
                        }
                    }
                    return x;
                })
            });
        }
    }

    showActiveTab = () => {
        const {tabList} = this.state;
        this.setState({
            tabList: tabList.map(x => {
                if (x.name === 'active call') {
                    x.hidden = false;
                }
                return x;
            })
        });
        this.handleChange2(null, 'active call')
    }

    getBackgroundColor = (status, _e) => {
        if (status === "closed:won") {
            return '#008f0e'
        } else if (status === "closed:lost") {
            return '#d82c2c'
        } else if (status === "closed:unsuccess") {
            return '#e7b80e'
        } else {
            return '#002ea7'
        }
    }

    filterTabList = (tabList) => {
        const {opportunity} = this.props;
        return tabList.filter(item => {
            // Enable this if need to hide the tab initially
            if (opportunity && !opportunity.main.self_opportunity && item.name === 'actions') {
                item.disabled = true;
            } else if (item.name === 'actions') {
                item.disabled = false;
            }

            return true;
        })
    }

    switchTo = (opportunityID, _event) => {
        const {navigate, isOpportunityDirty, setIsOpportunityDirty} = this.props;

        if (isOpportunityDirty) {
            OpportunityDirtyConfirmationPopup().then((result) => {
                if (result.isConfirmed) {
                    navigate(`/opportunities/id/${opportunityID}?opp=${opportunityID}`);
                    setIsOpportunityDirty(false);
                }
            });
        } else {
            navigate(`/opportunities/id/${opportunityID}?opp=${opportunityID}`);
        }
    }

    // Helper functions
    getItem = (array, current, direction) => {
        if (direction === 'next') {
            return array.indexOf(current) < array.length - 1 ? array[array.indexOf(current) + 1] : -1;
        }

        if (direction === 'previous') {
            return array.indexOf(current) !== 0 ? array[array.indexOf(current) - 1] : -1;
        }
    }

    render() {
        const {
            classes,
            stages,
            getOpportunityDetails_loading,
            opportunity_loading,
            getStages_loading,
            opportunity,
            salesReps_loading
        } = this.props;
        const {
            selectedTab,
            selectedTab2,
            pipelineStageId,
            tabList,
            previousOpportunity,
            nextOpportunity,
            selfOpportunity
        } = this.state;

        return (getOpportunityDetails_loading || opportunity_loading || salesReps_loading) ?
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 50}}>
                <CircularProgress/>
            </div> : (
                <div className={classes.container}>
                    <div className={classes.title}>
                        <div className={classes.flexCenter}>
                            {
                                !opportunity_loading && (
                                    <h2 className={classes.headline}>
                  <span className={classes.headlineSpan}>
                    <span>Opportunity</span><span className={classes.opportunityID}>{this.props.params.id}</span> <span
                      style={{background: this.props.opportunity && this.getBackgroundColor(this.props.opportunity.main.opportunity_status)}}
                      className={classes.status}>{this.props.opportunity && this.props.opportunity.main.opportunity_status}</span>
                  </span>
                                    </h2>
                                )
                            }
                        </div>
                        {
                            !opportunity_loading && (
                                <div className={classes.stepper}>
                                    <Stepper assignedOpportunity={opportunity && opportunity.main.self_opportunity}
                                             opportunity={opportunity} stages={stages} pipelineStageId={pipelineStageId}
                                             isLoading={getOpportunityDetails_loading || getStages_loading}/>
                                    {
                                        opportunity && selfOpportunity ? (
                                            <div className={classes.switcher}>
                                                {
                                                    previousOpportunity !== -1 && previousOpportunity !== undefined ? (
                                                        <Button className={classes.switcherBtn} color="inherit"
                                                                variant="contained"
                                                                onClick={(e) => this.switchTo(previousOpportunity, e)}>
                                                            <ChevronLeft sx={{width: '30px', height: '25px'}}/>
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                {
                                                    nextOpportunity !== -1 && nextOpportunity !== undefined ? (
                                                        <Button className={classes.switcherBtn} color="inherit"
                                                                variant="contained"
                                                                onClick={(e) => this.switchTo(nextOpportunity, e)}>
                                                            <ChevronRight sx={{width: '30px', height: '25px'}}/>
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                    {
                        (opportunity_loading || getOpportunityDetails_loading) ? (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 50}}>
                                <CircularProgress/>
                            </div>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={6} sx={{paddingTop: '0 !important'}}>
                                    <Tabs
                                        className={classes.tabs}
                                        value={selectedTab}
                                        onChange={this.handleChange}
                                        indicatorColor="primary"
                                        textColor="inherit"
                                        style={{backgroundColor: '#F1F1F1'}}
                                    >
                                        {
                                            this.filterTabList(tabList).map((item, index) => <Tab key={index}
                                                                                                  className={classes.tabsStyling}
                                                                                                  label={item.name}
                                                                                                  value={item.name}
                                                                                                  disabled={item.disabled}/>)
                                        }
                                    </Tabs>
                                    <div
                                        role="tabpanel"
                                        id={`full-width-tabpanel`}
                                        aria-labelledby={`full-width-tab`}
                                        style={{padding: '10px', background: '#efefef'}}
                                    >
                                        {
                                            this.renderSelectedComponent(selectedTab)
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={6} sx={{paddingTop: '0 !important'}}>
                                    <Tabs
                                        className={classes.tabs}
                                        value={selectedTab2}
                                        onChange={this.handleChange2}
                                        indicatorColor="primary"
                                        textColor="inherit"
                                        style={{backgroundColor: '#F1F1F1'}}
                                    >
                                        {
                                            this.filterTabList(tabList).map((item, index) => <Tab key={index}
                                                                                                  className={classes.tabsStyling}
                                                                                                  label={item.name}
                                                                                                  value={item.name}
                                                                                                  disabled={item.disabled}/>)
                                        }
                                    </Tabs>
                                    <div
                                        role="tabpanel"
                                        id={`full-width-tabpanel`}
                                        aria-labelledby={`full-width-tab`}
                                        style={{padding: '10px', background: '#efefef'}}
                                    >
                                        {
                                            this.renderSelectedComponent(selectedTab2)
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        )
                    }
                </div>
            );
    }
}

const mapStateToProps = (state) => ({
    getOpportunityDetails_loading: opportunitySelectors.getOpportunityDetails_loading(state),
    salesReps_loading: opportunitySelectors.salesReps_loading(state),
    opportunity_loading: opportunitySelectors.opportunity_loading(state),
    getStages_loading: opportunitySelectors.getStages_loading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    checkEvent_loading: opportunitySelectors.checkEvent_loading(state),
    stages: opportunitySelectors.stages(state),
    pipelines: dashboardSelectors.pipelines(state),
    user: accountSelectors.user(state),
    calendar: opportunitySelectors.calendar(state),
    userPipelineContent: opportunitySelectors.userPipelineContent(state),
    isOpportunityDirty: opportunitySelectors.isOpportunityDirty(state),
    eventsToOpportunities: opportunitySelectors.eventsToOpportunities(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getOpportunityDetails: opportunityActions.getOpportunityDetails,
        getStages: opportunityActions.getStages,
        getHistory: opportunityActions.getHistory,
        checkEvent: opportunityActions.checkEvent,
        unCheckEvent: opportunityActions.unCheckEvent,
        getPipelines: dashboardActions.getPipelines,
        getCalendar: opportunityActions.getCalendar,
        getUserPipelineContent: opportunityActions.getUserPipelineContent,
        setIsOpportunityDirty: opportunityActions.setIsOpportunityDirty,
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <OpportunityDetails {...props} params={params} navigate={navigate}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
