import React, {useCallback, useState} from "react";
import {makeAccessor, makeHeader, prepareFilterQuery, prepareSortQuery} from "../../Utils/gridUtils";
import {toast} from "react-toastify";
import {Alert, Grid, Typography} from "@mui/material";
import {getNewMembers} from "../../../API/changePersonJob";
import GridElement from "../../Common/GidElement";

const PersonGrid = (props) => {
    const {selectableCount, handleSelected} = props;
    const [selected, setSelected] = useState([]);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [error, setError] = useState(false);

    const [cols, _setCol] = useState([
        {
            column: "persons.person_id",
            accessor: makeAccessor("persons.person_id"),
            minWidth: 150,
            Header: makeHeader("persons.person_id"),
            filter: "fuzzyText",
        },
        {
            column: "persons.person_firstname",
            accessor: makeAccessor("persons.person_firstname"),
            minWidth: 150,
            Header: makeHeader("persons.person_firstname"),
            filter: "fuzzyText",
        },
        {
            column: "persons.person_lastname",
            accessor: makeAccessor("persons.person_lastname"),
            minWidth: 150,
            Header: makeHeader("persons.person_lastname"),
            filter: "fuzzyText",
        },
        {
            column: "jobs.job_title",
            accessor: makeAccessor("jobs.job_title"),
            minWidth: 150,
            Header: makeHeader("jobs.job_title"),
            filter: "fuzzyText",
        },
        {
            column: "jobs.job_email",
            accessor: makeAccessor("jobs.job_email"),
            minWidth: 150,
            Header: makeHeader("jobs.job_email"),
            filter: "fuzzyText",
        },
        {
            column: "companies.company_name",
            accessor: makeAccessor("companies.company_name"),
            minWidth: 150,
            Header: makeHeader("companies.company_name"),
            filter: "fuzzyText",
        },
        {
            column: "persons.person_linkedin_member_id",
            accessor: makeAccessor("persons.person_linkedin_member_id"),
            minWidth: 150,
            Header: makeHeader("LinkedIn ID"),
            filter: "fuzzyText",
        },
        {
            column: "persons.person_region",
            accessor: makeAccessor("persons.person_region"),
            minWidth: 150,
            Header: makeHeader("persons.person_region"),
            filter: "fuzzyText",
        },
        {
            column: "persons.person_country",
            accessor: makeAccessor("persons.person_country"),
            minWidth: 150,
            Header: makeHeader("persons.person_country"),
            filter: "fuzzyText",
        },
        {
            column: "jobs.job_phone",
            accessor: makeAccessor("jobs.job_phone"),
            minWidth: 150,
            Header: makeHeader("jobs.job_phone"),
            filter: "fuzzyText",
        },
        {
            column: "persons.person_mobile_number",
            accessor: makeAccessor("persons.person_mobile_number"),
            minWidth: 150,
            Header: makeHeader("persons.person_mobile_number"),
            filter: "fuzzyText",
        },
    ]);

    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {
        const _columns = columns.map((item) => item.column)

        let query = "",
            sort = "";

        query = query + prepareFilterQuery(filters, columns, 'persons');
        sort = prepareSortQuery(sortBy, columns);

        if (query.length > 0) {
            setLoading(true);

            getNewMembers({
                results: pageSize,
                columns: _columns,
                offset: pageIndex + 1,
                filter: query,
                sort: sort
            }).then(res => {
                setData(res.data.main);
                setPageCount(res.data.offset.highest_offset);
                setRowsCount(res.data.offset.rows_count);
                setLoading(false);
            }).catch((error) => {
                toast.error(error);
                setLoading(false);
            })
        }
    }, []);

    const resetFilters = useCallback(() => setFilters([]), []);

    const handleRowSelection = (checked, id) => {
        let prevSelected = [...selected];

        if (checked) {
            if (selectableCount === 1) {
                let row = data.filter(item => item.person_id === id);
                if (row.length) {
                    prevSelected = [row[0]];
                    setSelected(prevSelected);
                }
            } else {
                if (prevSelected.length < selectableCount) {
                    if (prevSelected.filter(item => item.person_id === id).length === 0) {
                        let row = data.filter(item => item.person_id === id);
                        if (row.length) {
                            prevSelected.push(row[0]);
                            setSelected(prevSelected);
                        }
                    }
                }
            }
        } else {
            let rowIdx = prevSelected.indexOf(item => item.person_id === id);
            prevSelected.splice(rowIdx, 1);
            setSelected(prevSelected);
        }

        handleSelected(prevSelected);
    }

    const personHasEmail = (person, checked) => {
        let hasError = true;

        if (!checked) {
            hasError = false;
        } else if (person.job_email) {
            hasError = false;
        }

        setError(hasError);

        return hasError;
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant={'span'}
                        component={'span'}
                        sx={{fontWeight: 'bold'}}
                    >
                        Search New Member

                    </Typography>
                    <Alert variant="outlined" severity="info"
                           style={{border: 'none', padding: '0px 10px', fontSize: '12px'}}>
                        Result will only contain people who does not have any purchase in network group
                    </Alert>
                    {
                        error && <Alert severity="error" style={{padding: '0px 10px', fontSize: '12px'}}
                        >
                            Select a person with email address
                        </Alert>
                    }

                </Grid>
                <Grid item xs={12} sx={{marginTop: '20px'}}>
                    <GridElement
                        columns={cols}
                        data={data}
                        fetchData={fetchData}
                        loading={loading}
                        pageCount={pageCount}
                        controlledPageIndex={controlledPageIndex}
                        setControlledPageIndex={setControlledPageIndex}
                        filters={filters}
                        setFilters={setFilters}
                        resetFilters={resetFilters}
                        rowsCount={rowsCount}
                        handleRowClick={() => {
                        }}
                        initialSortBy='person_firstname'
                        selectableCount={selectableCount}
                        handleRowSelection={handleRowSelection}
                        validateBeforeSelection={personHasEmail}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default PersonGrid;